import { Pais } from "./pais";
import { Provincia } from "./provincia";
import { TipoDocumento } from "./tipoDocumento";
import { TipoResponsableIva } from "./tipoResponsableIva";
import { UsuarioLogueado } from "./usuarioLogueado";

export class Cliente {
  id?: number;
  razonSocial?: string;
  numeroDocumento?: string;
  tipoDocumento?: TipoDocumento;
  nombreFantasia?: string;
  tipoResponsableIva?: TipoResponsableIva;
  domicilio?: string;
  pais?: Pais;
  provincia?: Provincia;
  departamento?: string;
  localidad?: string;
  codigoPostal?: string;
  telefono?: string;
  email?: string;
  clienteTipo?: any;
  usuarioAudit?: UsuarioLogueado;
  nombreCompleto;
  creado;
  nota?: string;

  constructor() {
    this.tipoDocumento = new TipoDocumento();
    this.tipoResponsableIva = new TipoResponsableIva();
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ScripthackComponent } from "../components/scripthack/scripthack.component";
import { TooltipModule } from "ng2-tooltip-directive";
import { ModalPluginComponent } from "../components/modal-plugin/modal-plugin.component";
import { SimplePageFormComponent } from "../components/general/simple-page-form/simple-page-form.component";
import { SimpleFormInputsComponent } from "../components/general/simple-page-form/simple-form-inputs/simple-form-inputs.component";
import { SimpleFormButtonsComponent } from "../components/general/simple-page-form/simple-form-buttons/simple-form-buttons.component";
import { SimpleFormInputDivComponent } from "../components/general/simple-page-form/simple-form-inputs/simple-form-input-div/simple-form-input-div.component";
import { SimpleFormActionsComponent } from "../components/general/simple-page-form/simple-form-actions/simple-form-actions.component";
import { SimpleFormHeaderComponent } from "../components/general/simple-page-form/simple-form-header/simple-form-header.component";
import { ExitoAnimationComponent } from "./exito-animation/exito-animation.component";
import { ErrorAnimationComponent } from "./error-animation/error-animation.component";
import { CargandoComponent } from "../components/cargando/cargando.component";
import { SeparadorComponent } from "./separador/separador.component";
import { TimePickerComponent } from "./time-picker/time-picker.component";
import { EmptyPipe } from "../pipes/empty";
import { SpanishPipe } from "../pipes/spanish.pipe";
import { SafePipe } from "../components/back-office/back-office-content/administracion/empresa/empresa-ver/empresa-ver.component";
import { CargarComponenteComponent } from "../components/cargando/cargar-componente/cargar-componente.component";
import { IframeComponent } from "../components/iframe/iframe.component";
import { PopupComponent } from "./popup/popup.component";
import { ConfigurarMpComponent } from "./popup/configurar-mp/configurar-mp.component";
import { CardComponent } from "./card/card.component";
import { SimpleBoxComponent } from "./simple-box/simple-box.component";

@NgModule({
  imports: [CommonModule, TooltipModule, FormsModule],

  declarations: [
    ScripthackComponent,
    ModalPluginComponent,
    SimplePageFormComponent,
    SimpleFormInputsComponent,
    SimpleFormButtonsComponent,
    SimpleFormInputDivComponent,
    SimpleFormActionsComponent,
    SimpleFormHeaderComponent,
    ExitoAnimationComponent,
    ErrorAnimationComponent,
    CargandoComponent,
    SeparadorComponent,
    TimePickerComponent,
    EmptyPipe,
    SafePipe,
    SpanishPipe,
    CargarComponenteComponent,
    IframeComponent,
    PopupComponent,
    ConfigurarMpComponent,
    CardComponent,
    SimpleBoxComponent
  ],
  exports: [
    FormsModule,
    ScripthackComponent,
    TooltipModule,
    ModalPluginComponent,
    SimplePageFormComponent,
    SimpleFormInputsComponent,
    SimpleFormButtonsComponent,
    SimpleFormInputDivComponent,
    SimpleFormActionsComponent,
    SimpleFormHeaderComponent,
    ExitoAnimationComponent,
    ErrorAnimationComponent,
    CargandoComponent,
    SeparadorComponent,
    TimePickerComponent,
    EmptyPipe,
    SafePipe,
    SpanishPipe,
    CargarComponenteComponent,
    IframeComponent,
    PopupComponent,
    ConfigurarMpComponent,
    CardComponent,
    SimpleBoxComponent
  ]
})
export class UtilidadesModule {}

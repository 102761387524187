import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-form-actions',
  templateUrl: './simple-form-actions.component.html',
  styleUrls: ['./simple-form-actions.component.css']
})
export class SimpleFormActionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

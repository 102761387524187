import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-autogestion-p1',
  templateUrl: './autogestion-p1.component.html',
  styleUrls: ['./autogestion-p1.component.css']
})
export class AutogestionP1Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

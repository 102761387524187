import { Injectable } from '@angular/core';

@Injectable()
export class ScriptService {

  constructor() { }


  public loadScripts(dynamicScripts) {
  
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < dynamicScripts.length; i++) {
      var isFound = false
      for (var a = 0; a < scripts.length; ++a) {
        if (scripts[a].getAttribute('src') != null && scripts[a].getAttribute('src').includes(dynamicScripts[i])) {
          isFound = true;
          break
        }
      }
      if (!isFound) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('body')[0].appendChild(node);
      }
    }
  }


  public destroyScripts(dynamicScripts) {

    for (let scriptSrc of dynamicScripts) {

      if (document.querySelector(`script[src="${scriptSrc}"]`)) {
        var parent = document.getElementsByTagName('body')[0]
        var child = document.querySelector(`script[src="${scriptSrc}"]`)
        parent.removeChild(child);
      }
    }

  }



}

import { Injectable } from "@angular/core";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { EmpresaService } from "./empresa.service";
import { HttpService } from "./http.service";
import { Router } from "@angular/router";
import { CobroService } from "./cobro.service";
import { Subject } from "rxjs";
import { FormasDePagoService } from "./formas-de-pago.service";
import { DetalleCobroMultiple } from "../models/cobro";

@Injectable()
export class CuentaCorrienteService {
  filtro;
  totalSaldos;
  cuentaCorrienteList;
  cuentaCorrienteCliente;
  importeResumen;
  textoResumen;
  resumen;
  resumenCargado;
  importeResumenPositivo;
  importeCobroActual;
  importeCC;
  debe;
  totalACobrar;
  mensajeCC;
  importeInput;
  deuda;
  cliente;

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private empresaService: EmpresaService,
    private http: HttpService,
    private router: Router,
    private cobroService: CobroService,
    private formaDePagoService: FormasDePagoService
  ) {}

  obtenerCuentaCorrienteCliente(cliente, detalle = false) {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerCuentaCorrienteCliente",
      {
        empresa: this.empresaService.empresa,
        cliente: cliente,
        detalle: detalle
      }
    );
  }

  obtenerCuentaCorrienteList() {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerListadoCuentaCorrientePorEmpresa",
      {
        empresa: this.empresaService.empresa,
        filtro: this.filtro
      }
    );
  }

  limpiarFiltros() {
    this.filtro = {
      activo: false,
      campos: { fechaDesde: "", fechaHasta: "", cliente: { id: "" } }
    };
  }

  calcularImportes() {
    this.importeCobroActual = this.cobroService.cobroNuevo.listadoDetalle[
      "0"
    ].importe;

    if (this.debe) {
      this.totalACobrar = this.importeCobroActual + this.importeResumenPositivo;
    } else {
      this.totalACobrar = this.importeCobroActual - this.importeResumen;
    }
    if (+this.totalACobrar < 0) {
      this.importeInput = 0;
    } else {
      this.importeInput = +this.totalACobrar;
    }
  }

  calcularEstadoCC() {
    let root = document.documentElement;

    this.cobroService.cobroNuevo.listadoDetalle[
      "0"
    ] = new DetalleCobroMultiple();

    this.deuda = undefined;

    if (this.importeInput > this.totalACobrar) {
      this.mensajeCC =
        "Luego de realizar el cobro el cliente tendrá a favor $ " +
        (this.importeInput - this.totalACobrar) +
        " en su cuenta corriente";

      this.cobroService.cobroNuevo.listadoDetalle[
        "0"
      ].importe = this.importeInput;

      this.cobroService.cobroNuevo.listadoDetalle["0"].cc.generar = true;

      this.cobroService.cobroNuevo.listadoDetalle["0"].cc.generar = true;
      this.cobroService.cobroNuevo.listadoDetalle["0"].cc.importeAfectado =
        this.importeInput - this.importeCobroActual;

      root.style.setProperty("--color-mensaje", "darkcyan");
    }

    if (this.importeInput < this.totalACobrar) {
      this.mensajeCC =
        "Luego de realizar el cobro el cliente tendrá una deuda de $ " +
        (this.totalACobrar - this.importeInput) +
        " en su cuenta corriente";

      this.deuda = this.totalACobrar - this.importeInput + this.importeResumen;

      this.cobroService.cobroNuevo.listadoDetalle[
        "0"
      ].importe = this.importeInput;

      root.style.setProperty("--color-mensaje", "darkred");
    }

    if (this.importeInput == this.totalACobrar) {
      this.mensajeCC =
        "Luego de realizar el cobro el cliente tendrá saldada su cuenta corriente";

      this.cobroService.cobroNuevo.listadoDetalle[
        "0"
      ].importe = this.importeInput;

      this.cobroService.cobroNuevo.listadoDetalle["0"].cc.generar = true;

      this.cobroService.cobroNuevo.listadoDetalle[
        "0"
      ].cc.importeAfectado = this.importeResumenPositivo;

      root.style.setProperty("--color-mensaje", "darkcyan");
    }
  }
}

import { Component, Inject, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CobroService } from "../../services/cobro.service";

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"]
})
export class DialogComponent implements OnInit {
  animal: string;
  name: string;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogTemplate, {      
      autoFocus: false,
      width: "250px",
      data: { name: this.name, animal: this.animal }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.animal = result[0];
    });
  }
}

@Component({
  selector: "dialog-template",
  templateUrl: "./dialog-template.html",
  styleUrls: ["./dialog.component.css"]
})
export class DialogTemplate {
  titulo;
  cancelar;
  aceptar;
  validar;

  @ViewChild('observacion') observacionInput: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<DialogTemplate>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }

  ngOnInit() {
    if (!this.data.titulo) {
      this.titulo = "¿Desea realizar alguna observación?";
    } else {
      this.titulo = this.data.titulo;
    }
    if (!this.data.cancelar) {
      this.cancelar = "No, gracias.";
    } else {
      this.cancelar = this.data.cancelar;
    }
    if (!this.data.aceptar) {
      this.aceptar = "Anular";
    } else {
      this.aceptar = this.data.aceptar;
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    if (this.data.validar) {
      var form = $("#dialogForm");
      let rules = {
        observacion: this.data.rules
      };

      validarFormulario(form, rules);      
    }
    this.setFocusToInput();
  }

  
  /*@HostListener('focusout', ['$event']) public onListenerTriggered(event: any): void {

  this.setFocusToInput();
  
  }*/
  
  setFocusToInput() {
    this.observacionInput.nativeElement.focus();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  autogrow(element) {
    element.style.height = "5px";
    element.style.height = element.scrollHeight + "px";
  }

  validacion() {
    this.data.observacion = this.data.observacion.trim();
    let data = this.data.observacion;
    if(this.data.banderaCodArea){
      if(this.data.codArea){
        if(this.data.codArea.length<=3 && this.data.observacion){
          data = [this.data.codArea,this.data.observacion];
        }else{
          return false;
        }
      }else{
        return false;
      }
    }else{
      if(this.data.observacion){
        data = [this.data.observacion];
      }else{
        return false;
      }
    }
    if (this.data.validar) {
      if (($("#dialogForm") as any).valid()) {
        this.dialogRef.close(data);
      } else {
        return false;
      }
    } else {
      this.dialogRef.close(data);
    }
  }
}

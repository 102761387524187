export class CuentaTipo {
    nombre
   codigo
   descripcion
   comprobantes
   empresas
   id
   usuarios
   precio
   clientes
   adicionales
}
import { Component, OnInit } from "@angular/core";
import { UsuarioService } from "../../../services/usuario.service";
import { EmpresaService } from "../../../services/empresa.service";
import { ClienteService } from "../../../services/cliente.service";
import { UtilidadesService } from "../../../services/utilidades.service";
import { AutogestionService } from "../../../services/autogestion.service";

@Component({
  selector: "app-autogestion-p3",
  templateUrl: "./autogestion-p3.component.html",
  styleUrls: ["./autogestion-p3.component.css"]
})
export class AutogestionP3Component implements OnInit {
  constructor(
    public usuarioService: UsuarioService,
    public empresaService: EmpresaService,
    public clienteService: ClienteService,
    public utilidadesService: UtilidadesService,
    public autogestionService: AutogestionService
  ) {}

  ngOnInit() {
    this.utilidadesService.autogestionHead = true;

    this.clienteService.solicitudListadoClientes().subscribe(
      data => {
        if (this.utilidadesService.dataErrorHandler(data)) {
          this.clienteService.clientesList = data["clienteList"];

          for (let cliente of this.clienteService.clientesList) {
            if (cliente.clienteInstance["razonSocial"] == "CONSUMIDOR FINAL") {
              this.autogestionService.cliente = cliente.clienteInstance;
              this.clienteService.clienteActual = this.autogestionService.cliente;
              break;
            }
          }
        }
      },
      error => {
        this.utilidadesService.errorHandler(error);
      }
    );
  }

  continuar() {
    //this.utilidadesService.loading = true;
    this.utilidadesService.setCargando(true);
  }
}

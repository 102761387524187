import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { CobroService } from "../../../../../../../../services/cobro.service";
import { UtilidadesService } from "../../../../../../../../services/utilidades.service";

@Component({
  selector: "app-modal-point",
  templateUrl: "./modal-point.component.html",
  styleUrls: ["./modal-point.component.css"]
})
export class ModalPointComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private cobroService: CobroService,
    public utilidadesService: UtilidadesService
  ) {}

  resultado;
  errorDetalle;
  result_status;
  mpTransId;
  empresaId;
  usuarioId;

  ngOnInit() {
    this.resultado = this.activatedRoute.snapshot.paramMap.get("resultado");

    if (this.resultado == "exito") {
      this.activatedRoute.queryParams.subscribe(params => {
        this.result_status = params["result_status"];
      });

      if (this.result_status == "OK") {
        this.cerrarVentana();
      } else {
        if (this.result_status == "USER_CANCELLED_ERROR") {
          this.mpTransId = this.activatedRoute.snapshot.paramMap.get(
            "mpTransId"
          );
          this.empresaId = this.activatedRoute.snapshot.paramMap.get(
            "empresaId"
          );
          this.usuarioId = this.activatedRoute.snapshot.paramMap.get(
            "usuarioId"
          );
          this.errorDetalle = this.result_status;
          this.cancelarTrans();
        }
      }
    } else {
      this.mpTransId = this.activatedRoute.snapshot.paramMap.get("mpTransId");
      this.empresaId = this.activatedRoute.snapshot.paramMap.get("empresaId");
      this.usuarioId = this.activatedRoute.snapshot.paramMap.get("usuarioId");
      this.activatedRoute.queryParams.subscribe(params => {
        this.errorDetalle = params["error_detail"];
      });
      this.cancelarTrans();
    }
  }

  cerrarVentana() {
    window.close();
  }

  cancelarTrans() {
    let datos = {
      empresa: { id: +this.empresaId },
      usuario: { id: +this.usuarioId },
      cobroDetalleMpTrans: {
        id: +this.mpTransId,
        mpStatusDetail: this.errorDetalle
      }
    };

    this.cobroService.cancelarTransPoint(datos).subscribe(
      data => {
        if (this.utilidadesService.dataErrorHandler(data)) {
          this.cerrarVentana();
        }
      },
      error => {
        this.utilidadesService.errorHandler(error);
        this.cerrarVentana();
      }
    );
  }
}

import { Component, OnChanges, OnInit, SimpleChanges, ViewEncapsulation, OnDestroy } from '@angular/core';
import { EmpresaService } from "../../../../../../services/empresa.service";
import { AccesoService } from "../../../../../../services/acceso.service";
import { UtilidadesService } from "../../../../../../services/utilidades.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { NavigationStart,NavigationEnd,NavigationError, Router, ActivatedRoute } from '@angular/router';
import { FormasDePagoService } from "../../../../../../services/formas-de-pago.service";
import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ScriptService } from "../../../../../../services/script.service";
import { ConfigService } from "../../../../../../services/config.service";
import { ActualizarPaginaService } from "../../../../../../services/actualizar-pagina.service";
import { UsuarioService } from "../../../../../../services/usuario.service";

import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
@Component({
  selector: "app-empresa-ver",
  templateUrl: "./empresa-ver.component.html",
  styleUrls: ["./empresa-ver.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class EmpresaVerComponent implements OnInit,OnDestroy {
  private empresaSubscripto: Subscription = null;
  cargando;
  url;
  botonMp;
  iframeMp;
  empresaDao;
  dynamicScripts = [
    "../assets/global/plugins/bootstrap-modal/js/bootstrap-modalmanager.js",
    "../assets/global/plugins/bootstrap-modal/js/bootstrap-modal.js",
    "../assets/pages/scripts/ui-extended-modals.min.js",
  ];
  constructor(
    private router: Router,
    public utilidadesService: UtilidadesService,
    public spinnerService: Ng4LoadingSpinnerService,
    public accesoService: AccesoService,
    public empresaService: EmpresaService,
    public formasDePagoService: FormasDePagoService,
    private scriptService: ScriptService,
    private configService: ConfigService,
    private actualizarPaginaService: ActualizarPaginaService,
    private usuarioService: UsuarioService,
    private routes:ActivatedRoute
  ) {
    this.empresaSubscription();
  }

  ngOnInit() {
    new Promise((resolve) => {
      this.scriptService.loadScripts(this.dynamicScripts);
      resolve(true);
    });
    //this.cargando = true;
    this.consultarEmpresa();
    //this.spinnerService.show();
  }

  ngOnDestroy() {
    //QUITAR SCRIPTS
    this.scriptService.destroyScripts(this.dynamicScripts);
    this.empresaSubscripto.unsubscribe();
  }

  consultarEmpresa(){
    let empresaSel;
    if(this.empresaService.banderaEmpresaSel){
      //this.empresaService.empresa = this.empresaService.empresaSel;
      empresaSel = this.empresaService.empresaSel;
    }else{
      //this.empresaService.empresa = this.empresaService.empresaLogin;
      empresaSel = this.empresaService.empresa;
    }
    //this.cargando = true;
    
    //var intervalo = setInterval(() => {
    this.spinnerService.show();
      this.empresaService.ObtenerDatosEmpresa(empresaSel).subscribe(
        (data) => {
          //clearInterval(intervalo);
          this.spinnerService.hide();
          //this.cargando = false;
          if (this.utilidadesService.dataErrorHandler(data)) {
            //this.empresaService.empresa = this.empresaService.completarEmpresaInstance(
              this.empresaDao = this.empresaService.completarEmpresaInstance(
              data["empresaInstance"],
              data["empresaInstanceComplemento"]
            );
            if(this.empresaService.banderaEmpresaSel){
              this.empresaService.empresaSel = this.empresaDao;
            }else{
              this.empresaService.empresa = this.empresaDao;
            }
            this.empresaService.datosCargados = true;
            //this.empresaService.empresa.numeroDocumento
            if (this.empresaDao.numeroDocumento == "A Completar") {
              //this.empresaService.empresa.numeroDocumento = undefined;
              this.empresaDao.numeroDocumento = undefined;
            }
          }
        },

        (error) => {
          this.utilidadesService.errorHandler(error);
        }
      );
      //this.spinnerService.hide();
      //this.cargando = false;
      if (this.formasDePagoService.kolektiMpClientId) {
        this.botonMp = true;
      } else {
      }
    //}, 200);
  }

  comprobarDatosCargados() {
    this.router.navigate(["/backoffice/administracion/punto-venta/editar"]);
    // this.router.navigate(["/backoffice/administracion/editarEmpresa"]);
  }

  activarPV(){    
    let empresaSel;
    empresaSel = this.empresaService.empresaSel;
    //this.spinnerService.show();
    this.empresaService.activarPopup(empresaSel);
  }

  abrirModal() {
    this.iframeMp = true;
  }

  credencialesMP() {
    this.actualizarPaginaService.quitarAlerta();

    let redirect_uri = `${this.configService.urlMp}?datos={"empresa":{"id":${this.empresaService.empresa.id}},"usuario":{"id":${this.usuarioService.usuarioLogueado.id}}}`;

    redirect_uri = encodeURIComponent(redirect_uri);

    this.url = `https://auth.mercadopago.com.ar/authorization?client_id=${this.formasDePagoService.kolektiMpClientId}&response_type=code&platform_id=mp&redirect_uri=${redirect_uri}`;

    window.location.href = this.url;
  }

  empresaSubscription(){
    this.empresaSubscripto = this.empresaService.empresaObservable.subscribe(empresa=>{
      this.empresaDao = empresa;
      if(this.empresaService.banderaEmpresaSel){
        this.empresaService.empresaSel = this.empresaDao;
      }else{
        this.empresaService.empresa = this.empresaDao;
      }
    });
  }
}


@Pipe({ name: "safe" })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilidadesService } from "../../services/utilidades.service";

@Component({
  selector: 'app-cargando-principal',
  templateUrl: './cargando-principal.component.html',
  styleUrls: ['./cargando-principal.component.css']
})
export class CargandoPrincipalComponent implements OnInit {
  private cargandosubscripto: Subscription = null;
  cargando:boolean;
  constructor(public utilidadesService: UtilidadesService) { }

  ngOnInit() {
    this.cargandoSubscripto();
  }

  ngOnDestroy() {
    this.cargandosubscripto.unsubscribe();
  }

  cargandoSubscripto() {
    this.cargandosubscripto = this.utilidadesService.cargandoObservable
    .subscribe((cargando: boolean) => {
      this.cargando = cargando;
    });
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextBoxComponent } from "./text-box/text-box.component";
import { HorizontalSelectComponent } from "./horizontal-select/horizontal-select.component";
import { HeaderBoxComponent } from "./header-box/header-box.component";
import { VolverBtnComponent } from "./volver-btn/volver-btn.component";
import { MaterialModule } from "../material/material.module";
import { BasicInputComponent } from "./basic-input/basic-input.component";
import { ReactiveFormsModule } from "@angular/forms";
import { InputPasswordComponent } from "./input-password/input-password.component";
import { InputEmailComponent } from "./input-email/input-email.component";
import { TituloComponent } from "./titulo/titulo.component";

import { CuitInputComponent } from "./cuit-input/cuit-input.component";
import { NgxMaskModule } from "ngx-mask";
import { NgxPrintModule } from "ngx-print";
import { AccionesComponent } from "./acciones/acciones.component";
import { ScreenCaptureComponent } from "./screen-capture/screen-capture.component";
import { ItemIconComponent } from "./item-icon/item-icon.component";
import { ContainerComponent } from "./container/container.component";
import { MaterialErrorComponent } from "./material-error/material-error.component";
import { EmailvalidatorDirective } from "../directives/emailvalidator.directive";
import { CuitvalidatorDirective } from "../directives/cuitvalidator.directive";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxMaskModule,
    NgxPrintModule,
  ],
  declarations: [
    TextBoxComponent,
    HorizontalSelectComponent,
    HeaderBoxComponent,
    VolverBtnComponent,
    BasicInputComponent,
    InputPasswordComponent,
    InputEmailComponent,
    TituloComponent,
    CuitInputComponent,
    AccionesComponent,
    ScreenCaptureComponent,
    ItemIconComponent,
    ContainerComponent,
    MaterialErrorComponent,
    EmailvalidatorDirective,
    CuitvalidatorDirective,
  ],
  exports: [
    TextBoxComponent,
    HorizontalSelectComponent,
    HeaderBoxComponent,
    VolverBtnComponent,
    BasicInputComponent,
    TituloComponent,
    CuitInputComponent,
    MaterialModule,
    ReactiveFormsModule,
    NgxMaskModule,
    AccionesComponent,
    ScreenCaptureComponent,
    ItemIconComponent,
    NgxPrintModule,
    ContainerComponent,
    EmailvalidatorDirective,
    CuitvalidatorDirective,
  ],
})
export class BasicComponentsModule {}

import { Component, EventEmitter, OnInit, Input, ViewEncapsulation, Output } from '@angular/core';
import { ScriptService } from '../../services/script.service';


@Component({
  selector: 'app-modal-plugin',
  templateUrl: './modal-plugin.component.html',
  styleUrls: ['./modal-plugin.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ModalPluginComponent implements OnInit {
  dynamicScripts = [
    "../assets/global/plugins/bootstrap-modal/js/bootstrap-modalmanager.js",
    "../assets/global/plugins/bootstrap-modal/js/bootstrap-modal.js",
    "../assets/pages/scripts/ui-extended-modals.min.js",
  ]

@Input() boton 
@Input() botonText 
@Input() titulo
@Input() metodoBoton
@Output() clickBoton = new EventEmitter();


  constructor(private scriptService:ScriptService) { }

  ngOnInit() {
 
    //CARGAR SCRIPTS
    new Promise((resolve) => {
      this.scriptService.loadScripts(this.dynamicScripts)
      resolve(true);
    });

 
     
  }

  ngOnDestroy(): void {
    this.scriptService.destroyScripts(this.dynamicScripts)
  }

 


}

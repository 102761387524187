import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormControl, Validators, AbstractControl } from "@angular/forms";
import { UtilidadesService } from "../../services/utilidades.service";

@Component({
  selector: "app-cuit-input",
  templateUrl: "./cuit-input.component.html",
  styleUrls: ["./cuit-input.component.css"],
})
export class CuitInputComponent implements OnInit {
  @Input() default = "";
  @Input() required;
  @Input() placeholder;
  @Input() hint;
  @Output() changeCuit = new EventEmitter();
  valid;
  value;
  inputCuit;
  validatorsList;

  constructor(public utilidadesService: UtilidadesService) {}

  ngOnInit() {
    if (this.required) {
      this.validatorsList = [Validators.required, this.validarCuit];
    }

    this.inputCuit = new FormControl(this.default, this.validatorsList);
    this.valueChange();
  }

  valueChange() {
    this.value = this.inputCuit.value;
    this.valid = this.inputCuit.valid;
    if (this.valid) {
      this.changeCuit.emit(this.value);
    }
  }

  validarCuit = (control: AbstractControl) => {
    if (this.utilidadesService.validarCuit(control.value)) {
      return null;
    } else {
      return { cuitValidator: { value: control.value } };
    }
  };
}

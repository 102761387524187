import { Component,ViewEncapsulation , OnInit,Inject, Input, ViewChild, ElementRef, HostListener,Output,EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import {ConfigService } from '../../../services/config.service';

import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-dialog-notificacion-template',
  templateUrl: './dialog-notificacion-template.component.html',
  styleUrls: ['./dialog-notificacion-template.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DialogNotificacionTemplateComponent implements OnInit {
  @ViewChild('notificacionMensaje') div: ElementRef;
  sanitizedHtml = null;
  constructor(
    public dialogRef: MatDialogRef<DialogNotificacionTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private domSanitizer: DomSanitizer,
    private el: ElementRef,
    private config:ConfigService
  ) { }

  ngOnInit() {    
    this.sanitizedHtml = this.domSanitizer.bypassSecurityTrustHtml(this.data.notificacion.mensaje);
    /*if(this.config.modo == 'sandbox'){

        this.sanitizedHtml = this.domSanitizer.bypassSecurityTrustHtml(`<div class="contenedor">
                <div id="calificacion">
                    <div class="parrafo_calificacion">
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
                    </div>
                    <div id="btn-calificaciones">
                        <div class="icono-positivo" onclick="ocultarCalificacion();master_eventos_analytics('Notificaciones - Positiva')">
                            <mat-icon class="mat-icon material-icons">thumb_up</mat-icon>
                        </div>
                        <div class="icono-negativo" onclick="ocultarCalificacion();master_eventos_analytics('Notificaciones - Negativa')">
                            <mat-icon class="mat-icon material-icons">thumb_down</mat-icon>
                        </div>
                    </div>
                </div>
                <div id="calificacion-respondida">
                    <mat-icon class="mat-icon material-icons">mood</mat-icon>
                    <div
                        class="alert alert-success"
                        role="alert"
                    >
                        <strong>Gracias por calificar</strong>
                    </div>
                    
                </div>
            </div>`);
    }else{
        this.sanitizedHtml = this.domSanitizer.bypassSecurityTrustHtml(this.data.notificacion.mensaje);
        //this.div.nativeElement.innerHTML = sanitizedHtml;
        //this.data.notificacion.mensaje;
    }*/
  }
  @Output() eventoNotificacionLeida = new EventEmitter<void>();

  @HostListener('click', ['$event.target'])
  onClick(target: HTMLElement) {
    const elementoPadre = target.closest('#btn-calificaciones');
        if (elementoPadre) {
            this.eventoNotificacionLeida.emit(this.data.notificacion);
        }
        /*else if(target.classList.contains('icono-positivo') || elementoPadre.classList.contains('icono-positivo')){
            this.eventoNotificacionLeida.emit(this.data.notificacion);
        }else if(target.classList.contains('icono-negativo') || elementoPadre.classList.contains('icono-negativo')){
            this.eventoNotificacionLeida.emit(this.data.notificacion);
        }*/
    }
}

import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.css"]
})
export class CardComponent implements OnInit {
  @Input() importe;
  @Input() titulo;
  @Input() color;
  @Input() icono;
  constructor() {}

  ngOnInit() {
    this.color = getComputedStyle(document.documentElement).getPropertyValue(
      "--" + this.color
    );
  }
}

import { EventEmitter, Injectable, NgZone } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ConfigService } from "./config.service";
import { AccesoService } from "../services/acceso.service";
import { UsuarioLogueado } from "../models/usuarioLogueado";
import { UsuarioEmpresa } from "../models/usuarioEmpresa";
import { Role } from "../models/Role";
import { Permission } from "../models/permission";
import { VerService } from "../services/ver.service";
import { UtilidadesService } from "../services/utilidades.service";
import { Router } from "@angular/router";
import { EmpresaService } from "./empresa.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { HttpService } from "./http.service";

@Injectable()
export class UsuarioService {
  url =
    this.config.protocolHTTP +
    "://" +
    this.config.ip +
    ":" +
    this.config.puerto +
    this.config.baseDir;
  usuarioLogueado: UsuarioLogueado;
  urlImagenAvatar: string;
  urlImagenAvatarVer;
  usuarioEmpresaList: Array<UsuarioEmpresa>;
  usuarioEmpresa: UsuarioEmpresa;
  rolesEmpresa: Array<Role>;
  rolActual: Role;
  usuarioActual;
  permisosEmpresa: Array<Permission>;
  usuarioList: Array<UsuarioLogueado>;
  usuarioSeleccionado: UsuarioLogueado;
  usuarioNuevo: UsuarioLogueado = new UsuarioLogueado();
  estadoo = "inactive";
  filtro = {
    activo: false,
    campos: { fechaDesde: "", fechaHasta: "", username: "" },
  };
  datosCargados;
  eliminarUsuario;
  usuarioActualizar: UsuarioLogueado;
  events = new EventEmitter();

  constructor(
    private zone: NgZone,
    private httpService: HttpService,
    private spinnerService: Ng4LoadingSpinnerService,
    private empresaService: EmpresaService,
    private verService: VerService,
    private router: Router,
    private utilidadesService: UtilidadesService,
    private http: HttpClient,
    private config: ConfigService,
    private accesoService: AccesoService
  ) {
    window["usuarioService"] = {
      zone: this.zone,
      componentFn: (value) => this.eliminar(value),
      componentFn2: () => window.history.back(),
      component: this,
    };
  }

  solicitarUsuarioListar() {
    return this.httpService.init(
      true,
      "post",
      "/rest/wsSelectorListadoUsuarioPorEmpresa",
      {
        empresa: this.empresaService.empresa
      }
    );
  }

  actualizarUsuarioActual() {
    this.datosCargados = false;
    this.httpService
      .init(true, "post", "/rest/wsObtenerDatosUsuarioPorEmpresa", {
        empresa: this.empresaService.empresa,
        usuario: this.usuarioActual,
      })
      .subscribe(
        (data) => {
          if (this.utilidadesService.dataErrorHandler(data)) {
            this.usuarioActual = this.completarUsuario(data);
            this.cargarImagenAvatarVer(this.usuarioActual.avatarFileName);
            this.datosCargados = true;
          }
        },
        (error) => {
          this.utilidadesService.errorHandler(error);
        }
      );
  }

  completarUsuario(usuario) {
    usuario["usuarioInstance"]["empresa"] =
      usuario["usuarioInstanceComplemento"]["empresa"];
    usuario["usuarioInstance"]["empresaInicio"] =
      usuario["usuarioInstanceComplemento"]["empresaInicio"];
    usuario["usuarioInstance"]["pais"] =
      usuario["usuarioInstanceComplemento"]["pais"];
    usuario["usuarioInstance"]["provincia"] =
      usuario["usuarioInstanceComplemento"]["provincia"];
    usuario["usuarioInstance"]["role"] =
      usuario["usuarioInstanceComplemento"]["role"];
    usuario["usuarioInstance"]["tipoDocumento"] =
      usuario["usuarioInstanceComplemento"]["tipoDocumento"];
    usuario["usuarioInstance"]["usuarioAudit"] =
      usuario["usuarioInstanceComplemento"]["usuarioAudit"];
    usuario["usuarioInstance"]["avatarFileName"] =
      usuario["usuarioInstanceComplemento"]["avatarFileName"];
    usuario["usuarioInstance"][
      "fechaNacimiento"
    ] = this.utilidadesService.formatearDateString(
      usuario["usuarioInstance"]["fechaNacimiento"]
    );

    return usuario["usuarioInstance"];
  }

  solicitudDatosUsuario() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: " Bearer " + this.httpService.token,
      }),
    };
    return this.http.get(
      this.url + "/rest/wsObtenerDatosUsuarioLogueado",
      httpOptions
    );
  }

  actualizarPerfilInfo(usuario) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: " Bearer " + this.httpService.token,
      }),
    };
    return this.http.post(
      this.url + "/rest/wsModificarDatosUsuarioLogueado",
      usuario,
      httpOptions
    );
  }

  cargarImagenAvatar(src) {
    this.urlImagenAvatar = this.config.url + src + "?" + new Date().getTime();
  }

  cargarImagenAvatarVer(src) {
    this.urlImagenAvatarVer =
      this.config.url + src + "?" + new Date().getTime();
  }

  obtenerPermisosCrearRol() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: " Bearer " + this.httpService.token,
      }),
    };
    return this.http.get(
      this.url + "/rest/wsObtenerListadoPermissionUsuarioLogueado",
      httpOptions
    );
  }

  cargarRol(rol) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: " Bearer " + this.httpService.token,
      }),
    };
    return this.http.post(this.url + "/rest/wsGenerarRole", rol, httpOptions);
  }

  crearUsuario(usuario) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: " Bearer " + this.httpService.token,
      }),
    };
    return this.http.post(
      this.url + "/rest/wsGenerarUsuario",
      { empresa: this.empresaService.empresa, usuario: usuario },
      httpOptions
    );
  }

  obtenerRolesEmpresa() {
    let rest = "/rest/wsObtenerListadoRolePorEmpresa";
    let envio = { empresa: this.empresaService.empresa };
    return this.post(rest, envio);
  }

  obtenerUsuariosEmpresa() {
    let rest = "/rest/wsObtenerListadoUsuarioPorEmpresa";
    let envio = { empresa: this.empresaService.empresa, filtro: this.filtro };
    return this.post(rest, envio);
  }

  obtenerRol(rol) {
    let rest = "/rest/wsObtenerDatosRolePorEmpresa";
    let envio = { empresa: this.empresaService.empresa, rol: rol };
    return this.post(rest, envio);
  }

  post(
    rest,
    envio,
    httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: " Bearer " + this.httpService.token,
      }),
    }
  ) {
    return this.http.post(this.url + rest, envio, httpOptions);
  }

  get(
    rest,
    httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: " Bearer " + this.httpService.token,
      }),
    }
  ) {
    return this.http.get(this.url + httpOptions);
  }

  actualizarUsuario(usuario) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: " Bearer " + this.httpService.token,
      }),
    };
    return this.http.post(
      this.url + "/rest/wsActualizarDatosCuentaUsuario",
      { empresa: this.empresaService.empresa, usuario: usuario },
      httpOptions
    );
  }

  resetearClaveUsuario(usuario) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: " Bearer " + this.httpService.token,
      }),
    };
    return this.http.post(
      this.url + "/rest/wsResetearClaveCuentaUsuario",
      { empresa: this.empresaService.empresa, usuario: usuario },
      httpOptions
    );
  }

  setUsuarioActual(event) {
    let input = $(event.target).find("input");
    let usuario:any = input.val();
    this.usuarioActual = JSON.parse(usuario);
    this.cargarImagenAvatarVer(this.usuarioActual.avatarFileName);
  }

  eliminarPopup(instancia) {
    swal(
      {
        title: "¿Estás seguro que deseas realizar esta acción?",
        text: "No podrás recuperar el elemento eliminado.",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Si, deseo eliminarlo!",
        closeOnConfirm: false,
      },
      function (isConfirm) {
        if (isConfirm) {
          window["usuarioService"].zone.run(() => {
            window["usuarioService"].componentFn(instancia);
          });
        } else {
          return false;
        }
      }
    );
  }

  eliminar(usuario) {
    this.spinnerService.show();
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: " Bearer " + this.httpService.token,
      }),
    };
    return this.http
      .post(
        this.url + "/rest/wsEliminarUsuario",
        { empresa: this.empresaService.empresa, usuario: usuario },
        httpOptions
      )
      .subscribe(
        (data) => {
          this.spinnerService.hide();
          if (
            data["responseCode"] != "200" &&
            data["responseCode"] != "201" &&
            data["responseCode"] != "202"
          ) {
            new swal(
              "Por favor, intenta nuevamente.",
              data["responseMsg"],
              "error"
            );
         
          } else {
            new swal("Usuario eliminado con éxito.", "", "success");
            this.events.emit("eliminar")
            this.router.navigate(["/backoffice/administracion/listarUsuario"]);
          }
        },

        (error) => {
          this.spinnerService.hide();
          this.utilidadesService.errorHandler(error);
          this.eliminarUsuario = true;
        }
      );
  }

  ActualizarUsuarioPerfil() {
    this.usuarioActualizar = this.utilidadesService.borrarAtributosVacios(
      this.usuarioActualizar
    );

    this.usuarioActualizar.email = this.usuarioActualizar.email.trim();

    if (
      this.usuarioActualizar.piso == null ||
      this.usuarioActualizar.piso == undefined
    ) {
      this.usuarioActualizar.piso = 0;
    }

    this.usuarioActualizar.empresaInicio.id = +this.usuarioActualizar
      .empresaInicio.id;
    this.usuarioActualizar.provincia.id = +this.usuarioActualizar.provincia.id;
    this.usuarioActualizar.piso = +this.usuarioActualizar.piso;

    this.spinnerService.show();
    // if (!$("#formInfoPerfil").valid()) {
    //   swal(
    //     "Atención",
    //     "Verifique el formulario, algunos campos están sin completar o poseen errores.",
    //     "error"
    //   );
    //   this.spinnerService.hide();
    // } else {
    let fecha = this.usuarioActualizar.fechaNacimiento;
    this.usuarioActualizar.fechaNacimiento = ($("#fechaNacimiento") as any).val();
    this.usuarioActualizar.empresaInicio.id = Number(
      this.usuarioActualizar.empresaInicio.id
    );
    this.usuarioActualizar.pais.id = Number(this.usuarioActualizar.pais.id);
    this.usuarioActualizar.provincia.id = Number(
      this.usuarioActualizar.provincia.id
    );
    this.usuarioActualizar.tipoDocumento.id = +this.usuarioActualizar
      .tipoDocumento.id;

   
    this.actualizarPerfilInfo(this.usuarioActualizar).subscribe(
      (data) => {
        this.spinnerService.hide();
        if (this.utilidadesService.dataErrorHandler(data)) {
          this.usuarioLogueado = data["usuarioLogueado"];
          this.usuarioLogueado.empresaInicio = {
            id: data["usuarioLogueado"]["empresaInicio"],
          };
          this.usuarioActualizar = this.usuarioLogueado;
          new swal(
            "Información Actualizada",
            "En breve los cambios impactaran en la aplicación. Muchas Gracias.",
            "success"
          );
        }
      },
      (error) => {
        this.spinnerService.hide();
        this.utilidadesService.errorHandler(error);
      }
    );

    this.usuarioActualizar.fechaNacimiento = fecha;
  }
}

export class FormaPago {
    abreviatura?:string
    class?:string
    codigo?:string
    creado?:Date
    habilitada?:boolean
    id?:number
    leyenda?:string
    logoName?:string
    logoNameInver?:string
    nombre?:string
    usuarioCreador?:object
    logoUrl?:string = ""

}
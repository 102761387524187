import { Injectable, isDevMode } from "@angular/core";
import { Router } from "@angular/router";
import { HttpService } from "./http.service";
import { environment } from "../../environments/environment";
import { variable } from "@angular/compiler/src/output/output_ast";
import { ActualizarPaginaService } from "./actualizar-pagina.service";
import { ConfigService } from "./config.service";
import { AccesoService } from "./acceso.service";
import { NgForm } from "@angular/forms";
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UtilidadesService {
  mostrarContenido = true;
  cargando;
  contenido = true;
  eliminar;
  mostrarLogoFormaPago = true;
  mercadoQR1;
  pim1;
  MP1;
  autogestion;
  botonCosto = false;
  urlCosto;
  video = true;
  loading = true;
  mensajeError;
  mostrarBotonVideo;
  autogestionHead;
  promptEvent;
  autogestionPim = false;
  autogestionMP = false;
  backOffice = true;
  agregarCliente;
  modoAsistido;
  notificaciones = [];
  previousRoute;
  bienvenida;
  private utilidadObserver = new Subject<any>();
  public utilidadObservable = this.utilidadObserver.asObservable();
  private cargandoObserver = new Subject<boolean>();
  public cargandoObservable = this.cargandoObserver.asObservable();

  alerta = function (e) {
    var confirmationMessage =
      "¿Seguro desea continuar? " +
      "Perderá los cambios no guardados y deverá volver a loguearse...";
    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  };
  constructor(
    public actualizarPaginaService: ActualizarPaginaService,
    private http: HttpService,
    private router: Router,
    private configService: ConfigService,
    public accesoService: AccesoService
  ) {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();

      this.promptEvent = event;
    });
  }

  limpiarEspacios(id) {
    $("#" + id).val(($("#" + id) as any).val().trim());
  }

  setFechaActual(inputID) {
    $("#" + inputID).val(this.formatearDateString(new Date()));
  }

  getEmail() {
    if (this.configService.modo == "sandbox") {
      return "test_user_2384329@testuser.com";
    } else {
      let randomstring = Math.random().toString(36).slice(-8);
      return randomstring + "@gmail.com";
    }
  }

  setHoraActual(inputID) {
    var d = new Date();
    var hr = d.getHours();
    var min = d.getMinutes();

    let minutos;
    if (min < 10) {
      minutos = "0" + min;
    } else {
      minutos = min;
    }

    let time = hr + ":" + minutos;

    $("#" + inputID).val(time);
  }

  autofocusForm() {
    setTimeout(() => {
      $("form:first *:input[type!=hidden]:first").focus();
    }, 300);
  }

  focus(id) {
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 300);
  }

  instalarPWA() {
    if (this.esMobile()) {
      this.promptEvent.prompt();
    }
  }
  esMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  consola(texto) {
    if (!environment.production) {
    }
  }

  isNull(variable) {
    if (variable == "null" || variable == undefined || variable == null) {
      return true;
    }
  }

  ShowErrors(formd: NgForm) {
    Object.keys(formd.controls).forEach((key) => {
      formd.controls[key].markAsTouched();
    });

    swal(
      "Atención",
      "Verifique el formulario, algunos campos están sin completar o poseen errores.",
      "error"
    );
  }

  borrarAtributosVacios(objeto) {
    for (let atributo in objeto) {
      if (objeto[atributo] == null || objeto[atributo] == "") {
        delete objeto[atributo];
      }
    }

    return objeto;
  }

  dataErrorHandler(data) {
    if (
      data["responseCode"] != "200" &&
      data["responseCode"] != "201" &&
      data["responseCode"] != "202"
    ) {
      //this.loading = false;
      this.setCargando(false);
      if (data["responseCode"] == "406") {
        new swal(
          "Por favor, intenta nuevamente.",
          data["responseMsg"]["errors"]["0"]["message"],
          "error"
        );
      } else {
        new swal(data["responseMsg"], data["detalleError"], "error");
      }
      return false;
    }
    return true;
  }

  errorHandler(error) {
    //this.loading = false;
    this.setCargando(false);
    if (error["status"] == 401) {
      new swal(
        {
          title: "Su sesión a expirado",
          text: "Vuelva a ingresar por favor",
          type: "warning",
          showCancelButton: false,
          confirmButtonClass: "btn-info",
          confirmButtonText: "Aceptar",
          closeOnConfirm: false,
        },
        (isConfirm) => {
          if (isConfirm) {
            this.actualizarPaginaService.quitarAlerta();
            window.location.reload();
          } else {
            this.actualizarPaginaService.quitarAlerta();
            window.location.reload();
          }
        }
      );
    } else {
      new swal(
        "Por favor, intenta nuevamente.",
        "No hemos podido conectarnos con el servidor principal, es posible que la información en pantalla no esté actualizada.",
        "error"
      );
    }
  }

  mensajeValidacion(){
    swal(
      "Atención",
      "Verifique el formulario, algunos campos están sin completar o poseen errores.",
      "error"
    );
  }

  inicio() {
    if (this.autogestion) {
      this.router.navigate(["/backoffice/cobro-rapido"]);
    } else {
      if (
        this.accesoService.permisosLogin.includes(
          "ROLE_PERMISSION_ASAT_Generic-Cajero"
        )
      ) {
        this.router.navigate(["/backoffice/menu-inicial"]);
        // this.route.navigate(["/backoffice/layout"]);
      } else {
        this.router.navigate(["/backoffice/tablero-general"]);
      }
    }
  }

  traducirBoolean(boolean) {
    if (boolean) {
      return "Si";
    } else {
      return "No";
    }
  }

  ceroEsGratis(precio) {
    if (precio == 0) {
      return "Gratis";
    } else {
      return "$" + precio;
    }
  }

  dosDecimales(obj) {
    for (let atributo in obj) {
      let number = +atributo;
      number.toFixed(2);
      atributo = String(number);
    }

    return obj;
  }

  formatearFechas(objectList, fechas) {
    for (let objeto of objectList) {
      for (let atributo in objeto) {
        for (let fecha of fechas)
          atributo == fecha
            ? (objeto[atributo] = this.formatearDateString(objeto[atributo]))
            : false;
      }
    }
  }

  formatearDateString(string) {
    let date = new Date(string);
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  reset() {
    this.contenido = false;
    setTimeout(() => {
      this.contenido = true;
    }, 1);
  }

  ulHtml(array) {
    let htmltext = "";

    if (array.length == 0) {
      return array[0];
    } else {
      for (let string of array) {
        htmltext += `<li> ${string} </li>`;
      }

      let ulInicio = `<ul>`;
      let ulFin = `</ul>`;

      let resultado = ulInicio + htmltext + ulFin;

      return resultado;
    }
  }

  filtrarObjetos(objectList, atributoYSubAtributoList) {
    let resultado;
    let subObjetos = [];

    for (let objeto of objectList) {
      for (let atributo in objeto) {
        for (let atributoYSubAtributo of atributoYSubAtributoList) {
          if (
            Array.isArray(objeto[atributo]) &&
            atributo == atributoYSubAtributo[0]
          ) {
            for (let subObjecto of objeto[atributo]) {
              let resultados = [];

              subObjetos.push(subObjecto);
              for (let subAtributo in subObjecto) {
                if (subAtributo == atributoYSubAtributo[1]) {
                  resultados.push(subObjecto[subAtributo]);
                }
              }
              objeto[atributoYSubAtributo[0]] = this.ulHtml(resultados);
            }
          } else if (this.isObject(objeto[atributo])) {
            if (objeto[atributo].hasOwnProperty(atributoYSubAtributo[1])) {
              objeto[atributo] = objeto[atributo][atributoYSubAtributo[1]];
            }
            subObjetos.push(objeto[atributo]);
          }

          resultado = [objectList, subObjetos];
        }
      }
    }

    return resultado;
  }

  isObject(value) {
    return value && typeof value === "object" && value.constructor === Object;
  }

  objectToString(object) {
    return JSON.stringify(object);
  }

  solicitudTipoDocumento() {
    return this.http.init(true, "get", "/rest/wsObtenerListadoTipoDocumento");
  }

  solicitudTipoResponsableIva() {
    return this.http.init(
      true,
      "get",
      "/rest/wsObtenerListadoTipoResponsableIva"
    );
  }

  solicitudProvincias(pais) {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerListadoProvinciaPorPais",
      { pais: pais }
    );
  }

  solicitudPaises() {
    return this.http.init(true, "get", "/rest/wsObtenerListadoPais");
  }

  validarCuit(cuit) {
    if (cuit == null || cuit == undefined || cuit == "") {
      return false;
    }

    if (cuit.length != 11) {
      return false;
    }

    var acumulado = 0;
    var digitos = cuit.split("");
    var digito = digitos.pop();

    for (var i = 0; i < digitos.length; i++) {
      acumulado += digitos[9 - i] * (2 + (i % 6));
    }

    var verif = 11 - (acumulado % 11);
    if (verif == 11) {
      verif = 0;
    }

    return digito == verif;
  }

  convertir_a_numero(string) {
    string = +string;
    return string;
  }

  notificarEvento(){
    this.utilidadObserver.next();
  }
  setCargando(cargando: boolean) {
    this.cargandoObserver.next(cargando);
  }

  obtenerNotificacionesObsoleto(cantidadNotificaciones,idEmpresa) {
    let texto = [ 'texto de prueba ','Texto de prueba de dos lineas completas asd asd asd ','Texto de prueba de tres lineassss asd asd awe asdas dsad '];
    let mensaje = [ '<b>texto</b> de prueba: mensaje de ejemplo que muestra en el modal ','Texto de prueba de dos lineas completas: mensaje de ejemplo que muestra en el modal, mensaje de ejemplo que muestra en el modal, mensaje de ejemplo que muestra en el modal ','Texto de prueba de tres lineassss asd asd awe asdas dsad: mensaje de ejemplo que muestra en el modal, mensaje de ejemplo que muestra en el modal, mensaje de ejemplo que muestra en el modal, mensaje de ejemplo que muestra en el modal, mensaje de ejemplo que muestra en el modal, mensaje de ejemplo que muestra en el modal, mensaje de ejemplo que muestra en el modal, mensaje de ejemplo que muestra en el modal, mensaje de ejemplo que muestra en el modal '];
    let indice = 0;
    
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerNotificacionesInApp",
      { empresa: {id:idEmpresa} }
    ).pipe(data =>{
      console.log(data);
      this.notificaciones = this.notificaciones.filter(
        (o) => o.tipo != 'notificacion'
      );
      for (var i = 0; i < cantidadNotificaciones; i++) {
        //const randomNum = Math.floor(Math.random() * 3);
        if(indice >= texto.length){
          indice = 0;
        }
        this.notificaciones.push({
          id: cantidadNotificaciones+"-"+i,
          tituloCorto: texto[indice]+""+cantidadNotificaciones+"-"+i,
          mensaje: mensaje[indice],
          tipo: "notificacion",
          leido: false,
          vencimiento: '2023-10-22T03:00:00Z'
        });
        indice ++;
      }
  
      /*if(!localStorage.getItem('notificaciones')){
        localStorage.setItem('notificaciones',JSON.stringify(this.notificaciones.filter(notificacion => notificacion.tipo == 'notificacion')));
      }else{
      }*/
      this.verificarNotificaciones();
      return data;
    });
  }

  obtenerNotificaciones2(idEmpresa) {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerNotificacionesInApp",
      { empresa: {id:idEmpresa} }
    ).pipe(map((data:any) =>{

      this.notificaciones = this.notificaciones.filter(
        (o) => o.tipo != 'notificacion'
      );
      for (var i = 0; i < data.notificacionesInApp.length; i++) {
        var fechaFormateada = this.obtenerFechaFormateada(data.notificacionesInApp[i].creado);
        this.notificaciones.push({
          id: data.notificacionesInApp[i].id,
          tituloCorto: data.notificacionesInApp[i].tituloCorto,
          mensaje: data.notificacionesInApp[i].mensaje,
          tipo: "notificacion",
          leido: false,
          vencimiento: data.notificacionesInApp[i].vencimiento,
          creado: fechaFormateada
        });
      }      
      this.verificarNotificaciones();
      return data;
    }));
    /*let notificacionesJSON = JSON.parse(jsonNotificaciones).notificacionesList;
    this.notificaciones = this.notificaciones.filter(
      (o) => o.tipo != 'notificacion'
    );
      this.notificaciones.push(...notificacionesJSON);*/
    /*if(!localStorage.getItem('notificaciones')){
      localStorage.setItem('notificaciones',JSON.stringify(this.notificaciones.filter(notificacion => notificacion.tipo == 'notificacion')));
    }else{
    }*/



    /*return this.http.init(
      true,
      "post",
      "/rest/wsObtenerListadoProvinciaPorPais",
      { pais: pais }
    );*/
  }

  verificarNotificaciones(){
    const fechaActual = new Date();
    const fechaVencimiento = new Date(fechaActual);
    fechaVencimiento.setDate(fechaVencimiento.getDate() );
    const timestamp = Math.floor(fechaVencimiento.getTime() / 1000);
    var notificacionesLocalStorage = [];
    //JSON.parse(localStorage.getItem('notificaciones'));
    if(localStorage.getItem('notificaciones')){
      notificacionesLocalStorage = JSON.parse(localStorage.getItem('notificaciones'));
      notificacionesLocalStorage = notificacionesLocalStorage.filter(notificacion=>{
        if((Date.parse(notificacion.vencimiento) /1000) > timestamp){
          return true;
        }else{
          return false;
        }
      });
      localStorage.setItem('notificaciones',JSON.stringify(notificacionesLocalStorage));
    }
    this.notificaciones = this.notificaciones.filter(notificacion => !notificacionesLocalStorage.some(notificacionLocalStorage => notificacion.id === notificacionLocalStorage.id));
    
    
    /*if(this.notificaciones){
      localStorage.setItem('notificaciones',JSON.stringify(this.notificaciones));
    }
    
    localStorage.removeItem('notificaciones');*/
  }

  obtenerFechaFormateada(fechaOriginal){
    const fecha = new Date(fechaOriginal);
    const dia = fecha.getDate().toString().padStart(2, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const anio = fecha.getFullYear().toString();
    const fechaFormateada = `${dia}/${mes}/${anio}`;
    return fechaFormateada;
  }
}

import { Injectable } from '@angular/core';
import { UsuarioService } from '../services/usuario.service';
import { UtilidadesService } from '../services/utilidades.service';
import {VerService } from '../services/ver.service';



@Injectable()
export class DatatableService {

  paginaActual = ""

  mostrarTabla: boolean
  columnasDesktop: Array<string> = []
  columnasTablet: Array<string> = []
  columnasPhone: Array<string> = []
  columnasOcultas: Array<string> = []
  objectList= []
  objectoPropiedades: Array<Array<any>> = []
  celdas: Array<string> = []
  id: Array<string> = []
  subObjetosNames = []
  subObjetosList = []
  fechas: Array<string> = []
  atributoYSubAtributoList: Array<Array<any>> = []
  

  constructor(public verService:VerService, private utilidadesService: UtilidadesService, private usuarioService: UsuarioService) { }

  clean() {
    this.paginaActual = ""

    this.mostrarTabla = false
    this.columnasDesktop = []
    this.columnasTablet = []
    this.columnasPhone = []
    this.columnasOcultas = []
    this.objectList = []
    this.objectoPropiedades = []
    this.celdas = []
    this.id = []
    this.subObjetosNames = []
    this.fechas = []
    this.atributoYSubAtributoList = []
    this.subObjetosList = []

  }


  crearTabla( objectList, propiedadesColumnas, nombreAtributoObjetoYnombreSubAtributoSubObjeto, fechas ) {
    
    this.objectList = objectList

    this.atributoYSubAtributoList = nombreAtributoObjetoYnombreSubAtributoSubObjeto

    this.fechas.length != 0 ? this.utilidadesService.formatearFechas(objectList,fechas) : false


    // if (this.atributoYSubAtributoList.length != 0 ){
    //  let objectListYsubObjectList = this.utilidadesService.filtrarObjetos(this.objectList, this.atributoYSubAtributoList)
    //  this.objectList = objectListYsubObjectList[0]
    //  this.subObjetosList = objectListYsubObjectList[1]

    // } 

    for (let propiedad of propiedadesColumnas) {

      if (propiedad[2] == "oculto") {
        this.columnasOcultas.push(propiedad[0])
      } else {

        if (this.objectoPropiedades.indexOf(propiedad) <= 0) {
          this.columnasPhone.push(propiedad[0])

        } else if (this.objectoPropiedades.indexOf(propiedad) >= 1 && this.objectoPropiedades.indexOf(propiedad) < 3) {
          this.columnasTablet.push(propiedad[0])

        } else if (this.objectoPropiedades.indexOf(propiedad) >= 3) {
          this.columnasDesktop.push(propiedad[0])

        }

      }

      this.celdas.push(propiedad[1])


    }

    this.mostrarTabla = true
   

  }




 

  






  }





    






  







import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoginOndemandComponent } from "./components/login-ondemand/login-ondemand.component";
import { AutogestionP1Component } from "./components/autogestion-p1/autogestion-p1.component";
import { AutogestionP2Component } from "./components/autogestion-p2/autogestion-p2.component";
import { AutogestionP3Component } from "./components/autogestion-p3/autogestion-p3.component";

const autogestionRoutes: Routes = [
  {
    path: "autogestion/:clave/:email/:importe/:id",
    component: LoginOndemandComponent
  },
  { path: "autogestion/p1", component: AutogestionP1Component },
  { path: "autogestion/p2", component: AutogestionP2Component },
  { path: "autogestion/p3", component: AutogestionP3Component }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(autogestionRoutes)
  ],
  declarations: [
    LoginOndemandComponent,
    AutogestionP1Component,
    AutogestionP2Component,
    AutogestionP3Component
  ],
  exports: [RouterModule]
})
export class AutogestionModule {}

import { Injectable } from '@angular/core';
import {  VerService } from '../services/ver.service';

@Injectable()
export class ModalsService {

  constructor(private verService :VerService ) { }



  crearSublink(subAtributoySubObjeto){

    return "hola"


}

}

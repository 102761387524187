import { Injectable } from "@angular/core";
import { UsuarioService } from "../services/usuario.service";
import { VerService } from "../services/ver.service";
import { UtilidadesService } from "../services/utilidades.service";
import { Router } from "@angular/router";
import { HttpService } from "../services/http.service";
import { DatatableService } from "../services/datatable.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";

import { ListarService } from "../services/listar.service";
@Injectable()
export class ETarjetaService {
  cierresLote;
  cierreLoteActual;
  transaccionActual;
  cargandoTransacciones = true;
  transaccionesCargadas = false;
  transaccionActualCargada = false;
  constructor(
    private utilidadesService: UtilidadesService,
    private verService: VerService,
    private listarService: ListarService,
    private http: HttpService,
    private usuarioService: UsuarioService,
    private router: Router,
    private datatableService: DatatableService,
    private spinnerService: Ng4LoadingSpinnerService
  ) {}

  listar() {
    this.listarService.paginaActual = "eTarjetaService";
    this.listarService.titulo = "Cierres de Lote";
    this.listarService.singular = "Cierre de Lote";
    this.router.navigate(["/backoffice/eTarjeta"]);
  }

  init() {
    this.cierresLote = [];
    this.obtenerCierresLote();
  }

  iniciarTabla() {
    this.datatableService.clean();
    this.datatableService.paginaActual = "eTarjeta";
    this.datatableService.atributoYSubAtributoList = [
      ["transacciones", "importe"]
    ];
    this.datatableService.objectList = this.cierresLote;
    this.datatableService.objectoPropiedades = [
      ["Id del lote", "idLote"],
      ["Número del Host", "numeroHost"],
      ["Nombre del Host", "nombreHost"],
      ["Terminal", "terminal"],
      ["Numero del Lote", "numeroLote"],
      ["Fecha de cierre", "fechaCierre"]
    ];
  }

  obtenerCierresLote() {
    this.http.init(true, "get", "/rest/wsObtenerCierresLote").subscribe(
      data => {
        if (this.utilidadesService.dataErrorHandler(data)) {
          this.utilidadesService.cargando = false;
          this.cierresLote = data["cierresLote"];
          this.prepararCierresLote();
        }
      },

      error => {
        this.spinnerService.hide();
        this.utilidadesService.errorHandler(error);
      }
    );
  }

  prepararCierresLote() {
    for (let cierreLote of this.cierresLote) {
      cierreLote["id"] = cierreLote["idLote"];
      cierreLote["transacciones"] = "Cargando...";
    }
  }

  verItem($event, objectList) {}

  obtenerTransacciones() {
    this.http
      .init(true, "post", "/rest/wsObtenerTxsPorCierreLote", {
        cierreLote: this.cierreLoteActual
      })
      .subscribe(
        data => {
          if (this.utilidadesService.dataErrorHandler(data)) {
            this.cierreLoteActual["transacciones"] = data["txsPorCierreLote"];

            this.cargandoTransacciones = false;
            this.transaccionesCargadas = true;
          }
        },

        error => {
          this.spinnerService.hide();
          this.utilidadesService.errorHandler(error);
        }
      );
  }
  completarCierreLote(transacciones) {
    // let transaccionesConId = this.agregarIdTransacciones(transacciones)
    // let cierreLote = this.verService.objeto
    // cierreLote["transacciones"] = transaccionesConId
    // this.verService.cleanShow ()
    // this.verService.objeto = cierreLote
    // this.verService.excepciones = ["id"]
    // this.verService.objectNameList = ["idTransaccion"]
    // this.verService.crearVista()
  }

  agregarIdTransacciones(transacciones) {
    transacciones.forEach(transaccion => {
      transaccion["id"] = transaccion["idTransaccion"];
    });

    return transacciones;
  }

  setCierreLoteActual(event) {
    let input = $(event.target).find("input");
    let cierreLote:any = input.val();
    this.cierreLoteActual = JSON.parse(cierreLote);
  }

  setTransaccionActual(event) {
    let input = $(event.target).find("input");
    let transaccion:any = input.val();
    this.transaccionActual = JSON.parse(transaccion);
    this.transaccionActualCargada = true;
  }
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "empty" })
export class EmptyPipe implements PipeTransform {
  transform(value) {
    if (value == null || value == "null" || value == undefined) {
      return "";
    } else {return value }
  }
}

import { FormaPago } from "./formaPago";

export class Cobro {
  cliente?: Cliente;
  importeTotal?: any;
  observacion?: string;
  link?: Link;
  listadoDetalle?: Array<DetalleCobroMultiple>;
  constructor() {
    this.link = new Link();
    this.cliente = new Cliente();
    this.listadoDetalle = [new DetalleCobroMultiple()];
  }
}

export class Link {
  id?: number;
}

export class DetalleCobroMultiple {
  formaPago?: FormaPago;
  importe?: string|number;
  itemComplemento?: ItemComplemento;
  cc: Cc;
  constructor() {
    this.formaPago = new FormaPago();
    this.itemComplemento = new ItemComplemento();
    this.cc = new Cc();
  }
}

export class Cliente {
  id?: number;
  numeroDocumento?;
}

export class ItemComplemento {
  observacion?: string;
  numero?: string;
  emisor?: string;
  fechaVencimiento?: string;
  entidadBancaria: EntidadBancaria;
  tarjetaEntidad: EntidadTarjeta;
  constructor() {
    this.entidadBancaria = new EntidadBancaria();
    this.tarjetaEntidad = new EntidadTarjeta();
  }
}

export class Cc {
  generar?: boolean;
  importeAfectado?: string;
  constructor() {
    this.generar = false;
  }
}

export class EntidadBancaria {
  id?: any;
  constructor() {}
}

export class EntidadTarjeta {
  id?: any;
  constructor() {}
}

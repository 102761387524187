import { Component, OnInit } from "@angular/core";
import { UtilidadesService } from "../../../services/utilidades.service";

@Component({
  selector: "app-autogestion-p2",
  templateUrl: "./autogestion-p2.component.html",
  styleUrls: ["./autogestion-p2.component.css"]
})
export class AutogestionP2Component implements OnInit {
  constructor(public utilidadesService: UtilidadesService) {}

  ngOnInit() {}
}

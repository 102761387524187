import { EventEmitter, Injectable, NgZone } from "@angular/core";
import { AperturaCaja } from "../models/aperturaCaja";
import { EmpresaService } from "./empresa.service";
import { HttpService } from "./http.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { Router } from "@angular/router";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { UtilidadesService } from "./utilidades.service";
import { CobroService } from "./cobro.service";

@Injectable()
export class CajaService {
  filtroListarCierreCaja;
  cierreCajaActual;
  cierreCajaEstadoList;

  aperturaCaja = new AperturaCaja();
  cajaMovimiento;
  listadoCajaMovimientoTipo;
  cajaMovimientoActual;
  cajaMovimientoList;
  cierreDeCajaFiltro = {
    fechaRefDesde: "",
    fechaRefHasta: "",
    fechaReferencia: ""
  };
  cierreCajafechaRef;
  filtro;
  totalesCierreCaja;
  camposExistencia = {
    campoEfectivo: {
      cajaMovimientoEfectivo: {
        ingresoEfectivoList: [],
        egresoEfectivoList: []
      },
      cobroEfectivoList: []
    },
    campoCheque: {
      cajaMovimientoCheque: {
        ingresoChequeList: [],
        egresoChequeList: []
      },
      cobroChequeList: []
    },
    campoVoucher: {
      cajaMovimientoVoucher: {
        ingresoVoucherList: [],
        egresoVoucherList: []
      },
      cobroVoucherList: []
    },
    campoMpQR: {
      cajaMovimientoMpQR: {
        ingresoMpQRList: [],
        egresoMpQRList: []
      },
      cobroMpQRList: []
    },
    campoPosV: {
      cajaMovimientoPosV: {
        ingresoPosVList: [],
        egresoPosVList: []
      },
      cobroPosVList: []
    },
    campoPim: {
      cajaMovimientoPim: {
        ingresoPimList: [],
        egresoPimList: []
      },
      cobroPimList: []
    },
    campoPoint: {
      cajaMovimientoPoint: {
        ingresoPointList: [],
        egresoPointList: []
      },
      cobroPointList: []
    },
    campoCuenta: {
      cobroCuentaList: []
    }
  };
  cajaCierre;
  cajaCierreShow;
  observacion;
  cierreCajaList;
  totalVentasShow;
  previousRoute;
  events = new EventEmitter();
  MovimientosEvents = new EventEmitter();

  constructor(
    public utilidadesService: UtilidadesService,
    private zone: NgZone,
    private router: Router,
    private empresaService: EmpresaService,
    private http: HttpService,
    private spinnerService: Ng4LoadingSpinnerService,
    private cobroService: CobroService
  ) {
    window["cajaService"] = {
      zone: this.zone,
      componentFn: value => this.eliminar(value),
      componentFn2: () =>
        this.router.navigate(["backoffice/caja/movimientoCaja/listar"]),
      component: this
    };
  }

  validarAccesoAdicionales() {
    return this.http.init(true, "post", "/rest/wsValidarAccesoAdicionales", {
      empresa: this.empresaService.empresa
    });
  }

  cajaCierreResumen() {
    return this.http.init(true, "post", "/rest/wsCajaCierreResumenPreliminar", {
      empresa: this.empresaService.empresa,
      cajaCierre: this.cierreDeCajaFiltro
    });
  }

  GenerarCajaCierre() {
    return this.http.init(true, "post", "/rest/wsGenerarCajaCierre", {
      empresa: this.empresaService.empresa,
      cajaCierre: this.cajaCierre
    });
  }

  generarCajaMovimiento() {
    return this.http.init(true, "post", "/rest/wsGenerarCajaMovimiento", {
      empresa: this.empresaService.empresa,
      cajaMovimiento: this.cajaMovimiento
    });
  }

  obtenerListadoCierreCaja() {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerListadoCajaCierrePorEmpresa",
      {
        empresa: this.empresaService.empresa,
        filtro: this.filtroListarCierreCaja
      }
    );
  }

  obtenerListadoCajaCierreEstado() {
    return this.http.init(
      true,
      "get",
      "/rest/wsObtenerListadoCajaCierreEstado"
    );
  }

  obtenerListadoCajaMovimientoTipo() {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerListadoCajaMovimientoTipo",
      { empresa: this.empresaService.empresa }
    );
  }

  completarCajaMovimiento(data) {
    data["cajaMovimientoInstance"]["caja"] =
      data["cajaMovimientoInstanceComplemento"]["caja"];
    data["cajaMovimientoInstance"]["cajaMovimientoTipo"] =
      data["cajaMovimientoInstanceComplemento"]["cajaMovimientoTipo"];
    data["cajaMovimientoInstance"]["formaPago"] =
      data["cajaMovimientoInstanceComplemento"]["formaPago"];
    data["cajaMovimientoInstance"]["caja"]["usuarioCajero"] =
      data["cajaMovimientoInstanceComplemento"]["usuarioCajero"];
    return data["cajaMovimientoInstance"];
  }

  completarCajaMovimientoList(cajaMovimientoList) {
    let list = [];

    for (let cajaMovimientoGroup of cajaMovimientoList) {
      this.completarCajaMovimiento(cajaMovimientoGroup);
      list.push(this.completarCajaMovimiento(cajaMovimientoGroup));
    }
    this.cajaMovimientoList = list;
  }

  obtenerCajaMovimiento() {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerDatosCajaMovimientoPorEmpresa",
      {
        empresa: this.empresaService.empresa,
        cajaMovimiento: this.cajaMovimientoActual
      }
    );
  }

  obtenerCierreCaja() {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerDatosCajaCierrePorEmpresa",
      {
        empresa: this.empresaService.empresa,
        cajaCierre: this.cierreCajaActual
      }
    );
  }

  obtenerCajaMovimientoList() {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerListadoCajaMovimientoPorEmpresa",
      { empresa: this.empresaService.empresa, filtro: this.filtro }
    );
  }

  setInstanceActual(event) {
    let input = $(event.target).find("input");
    let instance:any = input.val();
    this.cajaMovimientoActual = JSON.parse(instance);
  }

  consultarEliminar(instancia) {
    swal(
      {
        title: "¿Estás seguro que deseas realizar esta acción?",
        text: "No podrás recuperar el elemento eliminado.",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Si, deseo eliminarlo!",
        closeOnConfirm: false
      },
      function(isConfirm) {
        if (isConfirm) {
          window["cajaService"].zone.run(() => {
            window["cajaService"].componentFn(instancia);
          });
        } else {
          return false;
        }
      }
    );
  }

  eliminar(instancia) {
    this.spinnerService.show();
    this.http
      .init(true, "post", "/rest/wsEliminarCajaMovimiento", {
        empresa: this.empresaService.empresa,
        cajaMovimiento: instancia
      })
      .subscribe(
        data => {
          this.spinnerService.hide();

          if (
            data["responseCode"] != "200" &&
            data["responseCode"] != "201" &&
            data["responseCode"] != "202"
          ) {
            new swal(
              "Por favor, intenta nuevamente.",
              data["responseMsg"],
              "error"
            );

          } else {
            new swal("Movimiento de caja eliminado con éxito.", "", "success");
            this.events.emit("eliminar");
            this.router.navigate(["/backoffice/caja/movimientoCaja/listar"]);
          }
        },

        error => {
          this.spinnerService.hide();
          this.utilidadesService.errorHandler(error);
         
        }
      );
  }

  consultarEliminarCierreCaja(eliminar) {
    swal(
      {
        title: "¿Estás seguro que deseas realizar esta acción?",
        text: "No podrás recuperar el elemento eliminado.",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Si, deseo eliminarlo!"
      },
      isConfirm => {
        if (isConfirm) {
          eliminar();
        } else {
          return false;
        }
      }
    );
  }

  obtenerListadoCajaCierre2() {
    this.utilidadesService.cargando = true;
    this.obtenerListadoCierreCaja().subscribe(
      data => {
        if (this.utilidadesService.dataErrorHandler(data)) {
          this.cierreCajaList = data["cajaCierreList"];

          this.utilidadesService.cargando = false;
        }
      },
      error => {
        this.utilidadesService.errorHandler(error);
      }
    );
  }

  eliminarCierreCaja(instancia) {
    return this.http.init(true, "post", "/rest/wsEliminarCajaCierre", {
      empresa: this.empresaService.empresa,
      cajaCierre: instancia
    });
  }

  limpiarFiltroListarCajaCierre() {
    this.filtroListarCierreCaja = {
      activo: false,
      campos: { fechaDesde: "", fechaHasta: "", cajaCierreEstado: { id: "" } }
    };
  }

  setCierreCajaActual(e) {
    let input = $(event.target).find("input");
    let instance:any = input.val();

    instance = JSON.parse(instance);
    this.cierreCajaActual = instance["cajaCierreInstance"];

    this.spinnerService.show();
    this.obtenerCierreCaja().subscribe(
      data => {
        if (this.utilidadesService.dataErrorHandler(data)) {
          this.spinnerService.hide();

          this.cobroService.completarcobroList(
            data["cajaCierreInstanceComplemento"]["cobroList"]
          );
          this.totalesCierreCaja =
            data["cajaCierreInstanceComplemento"]["totales"];
          this.completarCajaMovimientoList(
            data["cajaCierreInstanceComplemento"]["cajaMovimientoList"]
          );

          this.totalesCierreCaja.totalVentas =
            data["cajaCierreInstanceComplemento"]["cajaCierreComplemento"][
              "totalVentas"
            ];

          this.totalVentasShow = this.totalesCierreCaja.totalVentas;

          this.totalesCierreCaja.totalDeducciones =
            data["cajaCierreInstanceComplemento"]["cajaCierreComplemento"][
              "totalDeducciones"
            ];

          this.totalesCierreCaja.totalAdicionales =
            data["cajaCierreInstanceComplemento"]["cajaCierreComplemento"][
              "totalAdicionales"
            ];

          this.totalesCierreCaja.totalFacturasSinCobrar =
            data["cajaCierreInstanceComplemento"]["cajaCierreComplemento"][
              "totalFacturasSinCobrar"
            ];

          this.cierreDeCajaFiltro.fechaReferencia =
            data["cajaCierreInstance"]["fechaReferencia"];
          this.cierreDeCajaFiltro.fechaRefDesde =
            data["cajaCierreInstance"]["fechaRefDesde"];
          this.cierreDeCajaFiltro.fechaRefHasta =
            data["cajaCierreInstance"]["fechaRefHasta"];

          this.router.navigate(["/backoffice/caja/cierre-de-caja/ver"]);
        }
      },
      error => {
        this.spinnerService.hide();
        this.utilidadesService.errorHandler(error);
      }
    );
  }

  tieneCobros(name) {
    let visible = this.totalesCierreCaja["cobro" + name] != 0 ? true : false;

    return visible;
  }

  tieneIngresos(name) {
    let visible =
      this.totalesCierreCaja["caja" + name + "In"] != 0 ? true : false;

    return visible;
  }

  tieneEgresos(name) {
    let visible =
      this.totalesCierreCaja["caja" + name + "Out"] != 0 ? true : false;

    return visible;
  }
}

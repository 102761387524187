import { Component, OnInit } from "@angular/core";
import { MercadoPagoService } from "../../../services/mercado-pago.service";

@Component({
  selector: "app-configurar-mp",
  templateUrl: "./configurar-mp.component.html",
  styleUrls: ["./configurar-mp.component.css"]
})
export class ConfigurarMpComponent implements OnInit {
  tutorial;
  constructor(public mercadoPagoService: MercadoPagoService) {}

  ngOnInit() {}

  cerrarPopup() {
    $("#cerrarPopup").click();
  }
}

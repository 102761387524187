import { EventEmitter, Injectable, NgZone } from "@angular/core";
import { VerService } from "../services/ver.service";
import { UtilidadesService } from "../services/utilidades.service";
import { Router } from "@angular/router";
import { HttpService } from "../services/http.service";
import { ListarService } from "./listar.service";
import { Cliente } from "../models/cliente";
import { EmpresaService } from "./empresa.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { CobroService } from "./cobro.service";
import { Subject } from 'rxjs';

@Injectable()
export class ClienteService {
  cliente = new Cliente();
  clientesList = [];
  clienteNuevo = new Cliente();
  clienteActual;
  clientesCargados;
  clienteActualComplemento;
  esEmpresa;
  filtro = {
    activo: true,
    campos: {
      fechaDesde: "",
      fechaHasta: "",
      numeroDocumento: "",
      razonSocial: ""
    }
  };
  datosCargados;
  cargandoSelector;
  vieneDeModoAsistido;
  clienteModoAsistido;
  vieneDeFacturacion;
  clienteFacturacion;
  private clienteobserver = new Subject<Cliente>();
  public clienteobservable = this.clienteobserver.asObservable();
  events = new EventEmitter();

  constructor(
    public utilidadesService: UtilidadesService,
    private zone: NgZone,
    private spinnerService: Ng4LoadingSpinnerService,
    private empresaService: EmpresaService,
    private http: HttpService,
    private verService: VerService,
    private router: Router,
    private listarService: ListarService,
    private cobroService: CobroService
  ) {
    window["clienteService"] = {
      zone: this.zone,
      componentFn: value => this.eliminar(value),
      componentFn2: () => window.history.back(),
      component: this
    };
  }

  actualizarClienteList() {
    this.solicitudListadoClientes().subscribe(
      data => {
        if (this.utilidadesService.dataErrorHandler(data)) {
          this.clientesList = data["clienteList"];
          this.clientesCargados = true;
          localStorage.clientesListCobroSimple = JSON.stringify(
            data["clienteList"]
          );
        }
      },
      error => {
        this.utilidadesService.errorHandler(error);
      }
    );
  }

  solicitudclienteTipoResponsableIva() {
    return this.http.init(
      true,
      "get",
      "/rest/wsObtenerListadoTipoResponsableIva"
    );
  }

  crearCliente(cliente) {
    return this.http.init(true, "post", "/rest/wsGenerarCliente", {
      empresa: this.empresaService.empresa,
      cliente: cliente
    });
  }

  solicitudListadoClientes() {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerListadoClientePorEmpresa",
      { empresa: this.empresaService.empresa, filtro: this.filtro }
    );
  }

  solicitudListadoClientesSelector() {
    return this.http.init(
      true,
      "post",
      "/rest/wsSelectorListadoClientePorEmpresa",
      { empresa: this.empresaService.empresa }
    );
  }

  actualizarCliente() {
    this.spinnerService.show();
    this.datosCargados = false;
    this.http
      .init(true, "post", "/rest/wsObtenerDatosClientePorEmpresa", {
        empresa: this.empresaService.empresa,
        cliente: this.clienteActual
      })
      .subscribe(
        data => {
          this.spinnerService.hide();
          if (this.utilidadesService.dataErrorHandler(data)) {
            this.cliente = this.completarclienteInstance(
              data["clienteInstance"],
              data["clienteInstanceComplemento"]
            );
            this.clienteActual = this.cliente;

            this.datosCargados = true;
          }
        },

        error => {
          this.spinnerService.hide();
          this.utilidadesService.errorHandler(error);
        }
      );
  }

  completarclienteInstance(clienteInstance, clienteInstanceComplemento) {
    clienteInstance["cuenta"] = clienteInstanceComplemento["cuenta"];
    clienteInstance["clienteConfig"] =
      clienteInstanceComplemento["clienteConfig"];
    clienteInstance["pais"] = clienteInstanceComplemento["pais"];
    clienteInstance["provincia"] = clienteInstanceComplemento["provincia"];
    clienteInstance["tipoDocumento"] =
      clienteInstanceComplemento["tipoDocumento"];
    clienteInstance["tipoResponsableIva"] =
      clienteInstanceComplemento["tipoResponsableIva"];
    clienteInstance["clienteTipo"] = clienteInstanceComplemento["clienteTipo"];
    clienteInstance["usuarioAudit"] =
      clienteInstanceComplemento["usuarioAudit"];

    return clienteInstance;
  }

  setClienteActual(event, soloSetear) {
    let input = $(event.target).find("input");
    let cliente:any = input.val();
    this.clienteActual = JSON.parse(cliente);

    if (soloSetear) {
      return false;
    }

    if (
      this.clienteActual.razonSocial == "CONSUMIDOR FINAL" &&
      event.target.id == "editar"
    ) {
      swal("Atención", "No es posible editar este cliente.", "error");
      this.router.navigate(["/backoffice/clientes"]);
    }
  }

  editar() {
    return this.http.init(true, "post", "/rest/wsActualizarCliente", {
      empresa: this.empresaService.empresa,
      cliente: this.clienteActual
    });
  }

  eliminarPopup(instancia) {
    swal(
      {
        title: "¿Estás seguro que deseas realizar esta acción?",
        text: "No podrás recuperar el elemento eliminado.",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Si, deseo eliminarlo!",
        closeOnConfirm: false
      },
      function(isConfirm) {
        if (isConfirm) {
          window["clienteService"].zone.run(() => {
            window["clienteService"].componentFn(instancia);
          });
        } else {
          return false;
        }
      }
    );
  }

  eliminar(cliente) {
    this.spinnerService.show();
    this.http
      .init(true, "post", "/rest/wsEliminarCliente", {
        empresa: this.empresaService.empresa,
        cliente: cliente
      })
      .subscribe(
        data => {
          this.spinnerService.hide();
          if (this.utilidadesService.dataErrorHandler(data)) {
            new swal("Cliente eliminado con éxito.", "", "success");
            this.events.emit("eliminar");
            //localStorage.removeItem("clientes");
            this.router.navigate(["/backoffice/clientes"]);
          }
        },

        error => {
          this.spinnerService.hide();
          this.utilidadesService.errorHandler(error);
        
        }
      );
  }

  notificarTipoDocumentoCliente(cliente: Cliente){
    this.clienteobserver.next(cliente);
  }
  
  setCliente(cliente: Cliente) {
    this.cliente = cliente;
    this.clienteobserver.next(this.cliente);
   }
 
   getCliente() {
    return this.cliente;
   }
}

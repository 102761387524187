import { Injectable } from "@angular/core";
import { UsuarioService } from "../services/usuario.service";
import { VerService } from "../services/ver.service";
import { UtilidadesService } from "../services/utilidades.service";
import { Router } from "@angular/router";
import { HttpService } from "../services/http.service";
import { ListarService } from "./listar.service";
import { CuentaNueva } from "../models/cuentaNueva";
import { CuentaTipo } from "../models/cuentaTipo";

import { EmpresaService } from "./empresa.service";

@Injectable()
export class CuentaService {
  cuentaInstance = {
    id: "cargando",
    nombre: "Cargando",
    descripcion: "Cargando",
    email: "Cargando",
    responsable: "Cargando",
    empresa: [{ nombreFantasia: "Cargando" }],
    tipoCuenta: new CuentaTipo()
  };
  cuentaInstanceComplemento;
  cuentaNueva = new CuentaNueva();
  cuentasCreadasList;

  constructor(
    private empresaService: EmpresaService,
    public utilidadesService: UtilidadesService,
    private http: HttpService,
    private verService: VerService,
    private usuarioService: UsuarioService,
    private router: Router,
    private listarService: ListarService
  ) {}

  ObtenerDatosCuenta() {
    return this.http.init(true, "post", "/rest/wsObtenerDatosCuenta", {
      empresa: this.empresaService.empresa
    });
  }

  completarCuentaInstance() {
    this.cuentaInstance["empresa"] = this.cuentaInstanceComplemento["empresa"];
    this.cuentaInstance["tipoCuenta"] = this.cuentaInstanceComplemento[
      "tipoCuenta"
    ];
  }

  crearModalVer(objeto) {}

  crearCuenta() {
    return this.http.init(false, "post", "/rest/wsSolicitarCuenta", {
      cuenta: this.cuentaNueva
    });
  }

  generarCuenta(codigo, email) {
    return this.http.init(false, "post", "/rest/wsGenerarCuenta", {
      codigo: codigo,
      email: email
    });
  }

  habilitarPlanCobroPlus(tipo) {
    return this.http.init(true, "post", "/rest/wsHabilitarPlanCobroPlus", {
      empresa: this.empresaService.empresa,
      tipoHabilitacion: tipo
    });
  }

  generarCuentasMasivas(cuentas) {
    return this.http.init(true, "post", "/rest/wsGenerarCuentasMasivas", {
      cuentas: cuentas
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-revisar-email',
  templateUrl: './revisar-email.component.html',
  styleUrls: ['./revisar-email.component.css']
})
export class RevisarEmailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';

@Injectable()
export class ListarService {

titulo
paginaActual
singular

  constructor() { }

}

import { Component, OnInit } from "@angular/core";
import { LoginService } from "../../../services/Login.service";
import { EmpresaService } from "../../../services/empresa.service";
import { UsuarioService } from "../../../services/usuario.service";
import { AccesoService } from "../../../services/acceso.service";
import { HttpService } from "../../../services/http.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { UtilidadesService } from "../../../services/utilidades.service";
import { AutogestionService } from "../../../services/autogestion.service";
import { timeout } from "rxjs/operators";
import { async } from "q";

declare var CryptoJS;

@Component({
  selector: "app-login-ondemand",
  templateUrl: "./login-ondemand.component.html",
  styleUrls: ["./login-ondemand.component.css"],
})
export class LoginOndemandComponent implements OnInit {
  username;
  password;
  id;
  importe;
  pass = "123654";
  texto;
  animation = false;
  error;
  constructor(
    private activatedRoute: ActivatedRoute,
    private servidor: LoginService,
    private httpService: HttpService,
    private utilidades: UtilidadesService,
    private empresaService: EmpresaService,
    private usuarioService: UsuarioService,
    private accesoService: AccesoService,
    private spinnerService: Ng4LoadingSpinnerService,
    private route: Router,
    private autogestionService: AutogestionService,
    public utilidadesService: UtilidadesService
  ) {}

  ngOnInit() {
    this.obtenerDatosUrl();
    //this.utilidadesService.loading = true;
    this.utilidadesService.setCargando(true);

    let dt = new Date();
    let hours = dt.getHours();

    if (hours >= 21 && hours <= 5) {
      this.texto = "¡Buenas Noches!";
    } else if (hours >= 6 && hours <= 11) {
      this.texto = "¡Buenos Días!";
    } else if (hours >= 12 && hours <= 20) {
      this.texto = "¡Buenas Tardes!";
    }
  }

  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
  }

  obtenerDatosUrl() {
    var encryptedUser = this.activatedRoute.snapshot.paramMap.get("email");
    var encryptedPass = this.activatedRoute.snapshot.paramMap.get("clave");
    this.autogestionService.importe = this.transformarImporte(
      this.activatedRoute.snapshot.paramMap.get("importe")
    );
    this.autogestionService.idLink = this.activatedRoute.snapshot.paramMap.get(
      "id"
    );
    this.password = decodeURIComponent(encryptedPass.toString());
    this.username = decodeURIComponent(encryptedUser.toString());

    this.login();
  }

  transformarImporte(importe) {
    var res = importe.replace("a", ".");
    return +res;
  }

  login() {
    this.servidor
      .autogestion(
        this.username,
        this.password,
        +this.autogestionService.idLink
      )
      .subscribe(
        (data) => {
          if (
            data["responseCode"] != "200" &&
            data["responseCode"] != "201" &&
            data["responseCode"] != "202"
          ) {
            if (data["responseCode"] == "406") {
              new swal(
                "Por favor, intenta nuevamente.",
                data["responseMsg"]["errors"]["0"]["message"],
                "error"
              );
            } else {
              this.utilidades.mensajeError = data["responseMsg"];
              //this.utilidadesService.loading = false;
              this.utilidadesService.setCargando(false);
              this.route.navigate(["autogestion/p2"]);
            }
          } else if (this.utilidades.dataErrorHandler(data)) {
            this.autogestionService.returnUrl =
              data["linkCobroAutogestionInstance"]["returnUrl"];
            this.autogestionService.externalRef =
              data["linkCobroAutogestionInstance"]["externalRef"];

            this.autogestionService.setLinks();

            this.httpService.token = data["token"];
            this.usuarioService.usuarioLogueado = this.usuarioService.completarUsuario(
              data
            );

            this.usuarioService.cargarImagenAvatar(
              data["usuarioInstance"].avatarFileName
            );

            let empresa = this.empresaService.completarEmpresaInstance(
              data["empresaInstance"],
              data["empresaInstanceComplemento"]
            );

            this.empresaService.empresa = empresa;

            this.usuarioService.usuarioLogueado.empresaInicio = this.empresaService.empresa;
            this.spinnerService.hide();
            this.accesoService.permisosLogin = data["roles"];
            this.utilidades.autogestion = true;

            if (
              this.empresaService.empresa.empresaConfig.pimWalletPass != null
            ) {
              this.utilidades.autogestionPim = true;
            }

            if (this.empresaService.empresa.empresaConfig.mpPublicKey != null) {
              this.utilidades.autogestionMP = true;
            }

            // if (
            //   this.accesoService.permisosLogin.includes(
            //     "ROLE_PERMISSION_ASAT_Generic-Cajero"
            //   )
            // ) {

            //   this.route.navigate(["/backoffice/cobro-rapido"]);
            // } else {
            //   this.route.navigate(["/backoffice/perfil"]);
            // }
            // }
            //this.utilidadesService.loading = false;
              this.utilidadesService.setCargando(false);

            if (
              this.utilidades.autogestionMP == false &&
              this.utilidades.autogestionPim == false
            ) {
              this.error = true;
              this.texto =
                this.empresaService.empresa.razonSocial +
                " no posee formas de pago habilitadas";

              //this.utilidadesService.loading = false;
              this.utilidadesService.setCargando(false);
            } else {
              //this.utilidadesService.loading = false;
              this.utilidadesService.setCargando(false);
              this.route.navigate(["autogestion/p3"]);
            }
          }
        },
        (error) => {
          this.spinnerService.hide();
          if (error["status"] == 401) {
            new swal(
              "Vuelva a intentar por favor",
              "Ingresaste un email o clave incorrecto, por favor, verifica ambos datos o prueba modificar la clave en el apartado ¿Olvidaste tu clave? cerrando esta ventana.",
              "error"
            );
          } else {
            new swal(
              "Por favor, intenta nuevamente.",
              "No hemos podido conectarnos con el servidor principal, es posible que la información en pantalla no esté actualizada.",
              "error"
            );
          }
        }
      );
  }
}

import { Directive } from "@angular/core";
import {
  Validator,
  NG_VALIDATORS,
  ValidatorFn,
  FormControl,
} from "@angular/forms";

@Directive({
  selector: "[appEmailvalidator]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useClass: EmailvalidatorDirective,
      multi: true,
    },
  ],
})
export class EmailvalidatorDirective implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = this.emailValidator();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }

  emailValidator(): ValidatorFn {
    return (control: FormControl) => {
      if (control.value != null && control.value !== "") {
        // /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/
        // /^[_a-z0-9-]+(.[a-z0-9-]+)@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$/
        // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3,4})+$/
        // /^[_a-z0-9-]+(.[a-z0-9-]*(\.[a-z]{1})+)@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/
        let isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(
          control.value
        );
        
        if (isValid) {
          return null;
        } else {
          return {
            emailvalidator: { valid: false },
          };
        }
      } else {
        return null;
      }
    };
  }
}

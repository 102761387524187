import { NgModule } from "@angular/core";

import {
  MatNativeDateModule,
  MatDatepickerModule,
  MatSelectModule,
  MatDialogModule,
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatFormFieldModule,
  MatButtonModule,
  MatToolbarModule,
  MatListModule,
  MatFormFieldControl,
  MatCheckboxModule,
  MatExpansionModule,
  MatIconModule,
  MatSidenavModule,
  MatMenuModule,
  MatBadgeModule
} from "@angular/material";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DialogComponent, DialogTemplate } from "./dialog/dialog.component";
import {MatRadioModule} from '@angular/material/radio';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from "@angular/material-moment-adapter";


import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { DialogNotificacionTemplateComponent } from './dialog/dialog-notificacion-template/dialog-notificacion-template.component';
import { DialogVideoAyudaTemplateComponent } from './dialog/dialog-video-ayuda-template/dialog-video-ayuda-template.component';

const material = [
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatFormFieldModule,
  MatButtonModule,
  MatToolbarModule,
  MatListModule,
  MatInputModule,
  MatTooltipModule,
  FormsModule,
  MatDialogModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatIconModule,
  MatSidenavModule,
  MatMenuModule,
  MatBadgeModule,
  MatRadioModule
];
export const FORMATO_FECHA = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
  },
};
/*monthYearLabel: 'MMM YYYY',
dateA11yLabel: 'LL',
monthYearA11yLabel: 'MMMM YYYY',*/

@NgModule({
  declarations: [DialogComponent, DialogTemplate, DialogNotificacionTemplateComponent, DialogVideoAyudaTemplateComponent],
  imports: [material, CommonModule, FormsModule],
  exports: [material],
  entryComponents: [DialogComponent, DialogTemplate, DialogNotificacionTemplateComponent, DialogVideoAyudaTemplateComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "es-ES" },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  ]
  
})
export class MaterialModule {}

import {
  trigger,
  state,
  animate,
  transition,
  style,
  keyframes
} from "@angular/animations";

export const fadeInAnimation = trigger("fadeInAnimation", [
  state("in", style({ opacity: 1 })),
  transition("*=> *", [
    style({ opacity: 0 }),
    animate("1s  ease-out", style({ opacity: 1 }))
  ])
]);

export const fadeInAnimation2 = trigger("fadeInAnimation2", [
  state("in", style({ opacity: 1 })),
  transition("*=> *", [
    style({ opacity: 0, transform: "translateY(20%)" }),
    animate(
      "0.4s  ease-out",
      style({ opacity: 1, transform: "translateY(0%)" })
    )
  ])
]);

export const openClose = trigger("openClose", [
  // ...
  state(
    "open",
    style({
      height: "*",
      opacity: 1,
      padding: "*",
      margin: "*"
    })
  ),
  state(
    "closed",
    style({
      height: 0,
      opacity: 0,
      padding: 0,
      margin: 0
    })
  ),
  transition("open => closed", [animate("500ms ease-in-out")]),
  transition("closed => open", [animate("500ms ease-in-out")]),

  state(
    "fadeIn",
    style({
      display: "block",
      opacity: 1,
      margin: 0
    })
  ),
  state(
    "fadeOut",
    style({
      display: "none",
      opacity: 0,
      margin: 0
    })
  ),

  state(
    "fadeOut2",
    style({
      display: "none",
      opacity: 0
    })
  ),

  transition("fadeIn => fadeOut", [
    animate(
      "500ms",
      keyframes([
        style({ position: "absolute", offset: 0 }),
        style({ opacity: 0, offset: 1 })
      ])
    )
  ]),

  transition("fadeOut => fadeIn", [animate("500ms")]),

  transition("fadeOut2 => fadeIn", [
    animate(
      "500ms",
      keyframes([
        style({ opacity: 0, offset: 0 }),

        style({ opacity: 1, offset: 1 })
      ])
    )
  ])
]);

export const openClose2 = trigger("openClose2", [
  // ...
  state(
    "open",
    style({
      height: "*",

      padding: "*",
      margin: "*"
    })
  ),
  state(
    "closed",
    style({
      height: 0,

      padding: 0,
      margin: 0
    })
  ),
  transition("open => closed", [animate("1s ease-in-out")]),
  transition("closed => open", [animate("1s ease-in-out")])
]);

import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UtilidadesService } from "./services/utilidades.service";
import { ScriptService } from "./services/script.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  title = "Cobro Simple";
  dynamicScripts = [
    "./assets/global/plugins/bootstrap/js/bootstrap.min.js",
    "./assets/global/plugins/js.cookie.min.js",
    "./assets/global/plugins/jquery-slimscroll/jquery.slimscroll.min.js",
    "./assets/global/plugins/jquery.blockui.min.js",
    "./assets/global/plugins/bootstrap-switch/js/bootstrap-switch.min.js",
    "./assets/global/plugins/bootstrap-sweetalert/sweetalert.min.js",
    "./assets/global/scripts/app.min.js",
    "./assets/components/index/js/index.js",
    "./assets/global/plugins/jquery-validation/js/jquery.validate.min.js",
    "./assets/global/plugins/jquery-validation/js/additional-methods.min.js",
    "./assets/components/login/js/login.component.js",
    "./assets/global/plugins/jquery-validation/js/localization/messages_es_AR.js"
  ];

  constructor(
    route: ActivatedRoute,
    public utilidadesService: UtilidadesService,
    private scriptService: ScriptService
  ) {}

  ngOnInit() {
    //CARGAR SCRIPTS
    this.utilidadesService.setCargando(true);
    this.scriptService.loadScripts(this.dynamicScripts);
    this.utilidadesService.setCargando(false);
    /*const promise1 = new Promise(resolve => {
      resolve(true);
    });

    promise1.then((value) => {
      if(value){
        this.utilidadesService.loading = false;
      }
    });*/
  }
/*
  ngAfterViewInit(): void {
    this.utilidadesService.loading = true;
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }*/
}

import { Role } from "./Role";
import { UsuarioEmpresa } from "./usuarioEmpresa";



export class UsuarioLogueado {
accountExpired?:boolean
accountLocked?:boolean
apellidos?:string
avatarFileName?:string
calle?:string
class?:string
codigoPostal?:string
creado?:string
departamento?:string
dpto?:string
email?:string
empresa?:Array<UsuarioEmpresa>
enabled?:boolean
estadoCivil?:string
fechaNacimiento?:string
id?:number
localidad?:string 
nacionalidad?:string
nombreCompleto?:string
numeroCasa?:string
numeroDocumento?:string
pais?:{id?:Number}
password?:string
passwordExpired?:boolean
piso?:number
provincia?:{id?:number}
role?:Array<Role>
sexo?:string
telefono?:string
telefonoAlt?:string
tipoDocumento?:{id?:number}
username?:string
usuario?:Array<object>
usuarioAudit ?: null
empresaInicio?:UsuarioEmpresa

  }
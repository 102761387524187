import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ConfigService } from "./config.service";
import { AccesoService } from "./acceso.service";

@Injectable()
export class HttpService {
  token;
  refreshToken;
  ultimoToken;

  constructor(private http: HttpClient, private configService: ConfigService) {}

  init(token, tipo, urlRest, envio = null) {
    let httpOptions;

    if (this.ultimoToken != undefined) {
      let minutosDiferencia = this.getMinutesBetweenDates(
        this.ultimoToken,
        new Date()
      );

      if (minutosDiferencia > 20 && minutosDiferencia < 6000) {
        this.actualizarToken();
      }
    }

    token
      ? (httpOptions = {
          headers: new HttpHeaders({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: " Bearer " + this.token
          })
        })
      : (httpOptions = {
          headers: new HttpHeaders({
            Accept: "application/json",
            "Content-Type": "application/json"
          })
        });

    switch (tipo) {
      case "post": {
        return this.http.post(
          this.configService.url + urlRest,
          envio,
          httpOptions
        );
      }

      case "get": {
        return this.http.get(this.configService.url + urlRest, httpOptions);
      }
    }
  }

  actualizarToken() {
    // var body = `grant_type=refresh_token&refresh_token=${this.refreshToken}`;

    // body = encodeURIComponent(body);

    // const params = new HttpParams()
    //   .set("grant_type", "refresh_token")
    //   .set("refresh_token", this.refreshToken);

    let body = new URLSearchParams();
    body.set("grant_type", "refresh_token");
    body.set("refresh_token", this.refreshToken);

    // const httpOptions = {
    //   params: body,
    //   headers: new HttpHeaders({
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   })
    // };

    let options = {
      headers: new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      )
    };

    return this.http
      .post(
        this.configService.url + "/oauth/access_token",
        body.toString(),
        options
      )
      .subscribe(
        data => {
          this.token = data["access_token"];
          this.refreshToken = data["refresh_token"];
          this.ultimoToken = new Date();
        },
        error => {}
      );
  }

  getMinutesBetweenDates(startDate, endDate) {
    var diff = endDate.getTime() - startDate.getTime();
    return diff / 60000;
  }
}

import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { AccesoGuard } from "./acceso.guard";
import { CargarEmpresaComponent } from "./components/cargar-empresa/cargar-empresa.component";
import { RegistroCuentaComponent } from "./components/registro-cuenta/registro-cuenta.component";
import { Wform1Component } from "./components/registro-cuenta/wform-1/wform-1.component";
import { Wform2Component } from "./components/registro-cuenta/wform-2/wform-2.component";
import { Wform3Component } from "./components/registro-cuenta/wform-3/wform-3.component";
import { ConfirmacionCuentaComponent } from "./components/registro-cuenta/confirmacion-cuenta/confirmacion-cuenta.component";
import { CuentaExitoComponent } from "./components/registro-cuenta/cuenta-exito/cuenta-exito.component";
import { ModalPostVirtualComponent } from "./components/modal-post-virtual/modal-post-virtual.component";
import { ModalPostVirtual2Component } from "./components/modal-post-virtual/modal-post-virtual2/modal-post-virtual2.component";
import { AutorizarCredencialesMpComponent } from "./components/back-office/back-office-content/administracion/empresa/empresa-ver/autorizar-credenciales-mp/autorizar-credenciales-mp.component";
import { QrTemplateComponent } from "./components/back-office/back-office-content/cobros/cobro-rapido/cobro-layout/mercado-pago/qr-template/qr-template.component";
import { ResetearClaveAComponent } from "./components/resetear-clave/resetear-clave-a/resetear-clave-a.component";
import { ResetearClaveBComponent } from "./components/resetear-clave/resetear-clave-b/resetear-clave-b.component";
import { ModalPointComponent } from "./components/back-office/back-office-content/cobros/cobro-rapido/cobro-layout/point/modal-point/modal-point.component";
import { ModalPostVirtual3Component } from "./components/modal-post-virtual/modal-post-virtual3/modal-post-virtual3.component";
import { RevisarEmailComponent } from "./components/registro-cuenta/revisar-email/revisar-email.component";
import { IframeComponent } from "./components/iframe/iframe.component";

const appRoutes: Routes = [
  {
    path: "iframe",
    component: IframeComponent,
  },

  {
    path: "autogestion",
    loadChildren: "app/autogestion/autogestion.module#AutogestionModule",
  },

  // {
  //   path: "registro-alta-cliente",
  //   loadChildren: "app/components/registro/registro.module#RegistroModule"
  // },

  {
    path: "login",
    loadChildren: "app/components/login/login.module#LoginModule",
  },

  {
    path: "modal-post-virtual",
    component: ModalPostVirtualComponent,
  },
  {
    path: "modal-post-virtual/2",
    component: ModalPostVirtual2Component,
  },
  {
    path: "modal-post-virtual/3",
    component: ModalPostVirtual3Component,
  },
  {
    path: "modal-point/:resultado/:empresaId/:usuarioId/:mpTransId",
    component: ModalPointComponent,
  },

  // {
  //   path: "imprimir-qr",
  //   component: QrTemplateComponent
  // },

  {
    path: "autorizar-credenciales-mp",
    component: AutorizarCredencialesMpComponent,
  },

  {
    path: "backoffice",
    loadChildren:
      "app/components/back-office/back-office.module#BackOfficeModule",
    canActivate: [AccesoGuard],
  },
  {
    path: "cargarEmpresa",
    component: CargarEmpresaComponent,
  },
  {
    path: "resetear-clave/1",
    component: ResetearClaveAComponent,
  },
  {
    path: "resetear-clave/2",
    component: ResetearClaveBComponent,
  },
  // {
  //   path: "cuenta/registro/confirmacion/:codigo/:email",
  //   component: ConfirmacionCuentaComponent
  // },
  // {
  //   path: "cuenta/registro/exito",
  //   component: CuentaExitoComponent
  // },
  // {
  //   path: "cuenta/registro/revisar-email",
  //   component: RevisarEmailComponent
  // },
  // {
  //   path: "cuenta/registro",
  //   component: RegistroCuentaComponent,
  //   children: [
  //     {
  //       path: "",
  //       component: Wform1Component
  //     },

  //     {
  //       path: "2",
  //       component: Wform2Component
  //     },

  //     {
  //       path: "3",
  //       component: Wform3Component
  //     }
  //   ]
  // },

  { path: "**", redirectTo: "/login", pathMatch: "full" },
  
];
// {
//   preloadingStrategy: PreloadAllModules
// }
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-acciones",
  templateUrl: "./acciones.component.html",
  styleUrls: ["./acciones.component.css"]
})
export class AccionesComponent implements OnInit {
  @Input() botonFacturar = false;
  @Input() botonListar = true;
  @Input() botonImprimir = true;
  @Input() botonCobrar = false;
  @Input() botonAnular;

  @Output() facturar = new EventEmitter();
  @Output() listar = new EventEmitter();
  @Output() imprimir = new EventEmitter();
  @Output() cobrar = new EventEmitter();
  @Output() anular = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}

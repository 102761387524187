import { Injectable } from "@angular/core";
import { UsuarioEmpresa } from "../models/usuarioEmpresa";
import {
  HttpClient,
  HttpHeaders,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { ConfigService } from "./config.service";
import { HttpService } from "./http.service";

@Injectable()
export class AccesoService {
  permisosLogin: String[];
  username;
  password;

  constructor(
    private config: ConfigService,
    private http: HttpService,
    private http2: HttpClient
  ) {}

  estaLogueado() {
    if (this.http.token != undefined) {
      return true;
    } else {
      return false;
    }
  }

  comprobarPermisos(permisos) {
    for (let permiso of permisos) {
      if (this.permisosLogin.includes(permiso)) {
        return true;
      }
    }
    return false;
  }

  solicitarPermisosEmpresa(empresa) {
    return this.http.init(true, "post", "/rest/wsCambiarEmpresaActual", {
      empresa: empresa
    });
  }

  validarAccesoAdicionales(tipo, empresa) {
    return this.http.init(true, "post", "/rest/wsValidarAccesoAdicionales", {
      empresa: empresa,
      tipoValidacion: tipo
    });
  }
}

import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-titulo",
  templateUrl: "./titulo.component.html",
  styleUrls: ["./titulo.component.css"],
})
export class TituloComponent implements OnInit {
  @Input() texto;
  @Input() sinSeparador;
  @Input() color;
  @Input() icono;
  @Input() padding;
  style;
  constructor() {}

  ngOnInit() {
    this.color = this.color ? this.color : "lightseagreen";

    this.style = { color: this.color };
  }
}

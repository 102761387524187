import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "spanish"
})
export class SpanishPipe implements PipeTransform {
  traducciones = [
    ["approved", "Aprobada"],
    ["refunded", "Devuelto"],
    ["rejected", "Rechazada"],
    ["accredited", "Listo, se acreditó tu pago!"],
    ["cc_rejected_bad_filled_card_number", "Revisa el número de tarjeta."],
    ["cc_rejected_bad_filled_date", "Revisa la fecha de vencimiento."],
    ["cc_rejected_bad_filled_other", "Revisa los datos."],
    ["cc_rejected_bad_filled_security_code", "Revisa el código de seguridad."],
    [
      "cc_rejected_blacklist",
      "Temporalmente tu tarjeta se encuentra inhabilitada para operar en mercado pago."
    ],
    [
      "cc_rejected_call_for_authorize",
      "Debes autorizar el pago. Llama al teléfono que se encuentra en el dorso de tu tarjeta."
    ],
    ["cc_rejected_card_disabled", "Tu tarjeta se encuentra deshabilitada."],
    ["cc_rejected_card_error", "Tubimos un error con tu tarjeta."],
    [
      "cc_rejected_duplicated_payment",
      "Ya hiciste un pago por ese valor. Si necesitas volver a pagar usa otra tarjeta u otro medio de pago."
    ],
    ["cc_rejected_high_risk", "Alto riesgo. Múltiples intentos."],
    ["cc_rejected_insufficient_amount", "No tiene fondos suficientes."],
    [
      "cc_rejected_invalid_installments",
      "Tu tarjeta no procesa pagos en cuotas."
    ],
    [
      "cc_rejected_max_attempts",
      "Llegaste al límite de intentos permitidos. Elige otra tarjeta u otro medio de pago."
    ],
    ["cc_rejected_other_reason", "No pudimos procesar tu pago."]
  ];

  resultado;

  transform(value: any, args?: any): any {
    for (let element of this.traducciones) {
      if (element[0] == value) {
        this.resultado = element[1];
        break;
      }
    }

    if (this.resultado == undefined) {
      return value;
    } else {
      return this.resultado;
    }
  }
}

import { EventEmitter, Injectable, NgZone } from "@angular/core";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { EmpresaService } from "./empresa.service";
import { HttpService } from "./http.service";
import { Router } from "@angular/router";
import { UtilidadesService } from "./utilidades.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DialogTemplate } from "../material/dialog/dialog.component";
import { isUndefined } from "util";
import { FormasDePagoService } from "./formas-de-pago.service";
import { Cobro } from "../models/cobro";
import { FacturacionService } from "./facturacion.service";
import { UsuarioService } from "./usuario.service";
import { AccesoService } from "./acceso.service";

@Injectable()
export class CobroService {
  cobroNuevo = new Cobro();
  entidadBancariaList = [{}];
  cobrosList = [];
  numero = 1;
  cobroActual;
  multiCobroList = [];
  restoCM;
  barra = "0%";
  cobroDetalleMpTrans = null;
  cobroDetalleMpTransPosV = null;
  mpQR = "";
  filtro;
  publicKey;
  transactionAmount = "0.01";
  cobroMultiple;
  mpDeeplink;
  tarjetaEntidadList;
  events = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private utilidadesService: UtilidadesService,
    private zone: NgZone,
    private spinnerService: Ng4LoadingSpinnerService,
    private empresaService: EmpresaService,
    private http: HttpService,
    private router: Router,
    private formasDePagoService: FormasDePagoService,
    private facturacionService: FacturacionService,
    private usuarioService: UsuarioService,
    private accesoService: AccesoService
  ) {}

  cargarCobro() {
    return this.http.init(true, "post", "/rest/wsGenerarCobro", {
      empresa: this.empresaService.empresa,
      cobro: this.cobroNuevo,
    });
  }

  finalizarCobro(data) {
    this.cobroActual = this.completarCobroInstance(data);

    let mensaje =
      "Se cobraron $" +
      this.cobroActual.importeTotal +
      " con " +
      this.cobroActual.cobroDetalle[0].formaPago.nombre +
      ".";
    this.popUpCobroRealizado(mensaje);
    this.limpiarCobroNuevo();
    this.router.navigate(["/backoffice/ver-cobro"]);
  }

  popUpCobroRealizado(mensaje) {
    if (
      this.accesoService.comprobarPermisos([
        "ROLE_PERMISSION_Comprobante_crear",
      ])
    ) {
      swal(
        {
          title: "Cobro Realizado",
          text: mensaje,
          type: "success",
          showCancelButton: true,
          cancelButtonText: "OK",
          confirmButtonClass: "btn-danger",
          confirmButtonText: "Facturar",
          closeOnConfirm: this.empresaService.empresa.facturaElectronica,
        },
        (isConfirm) => {
          if (isConfirm) {
            if (this.empresaService.empresa.facturaElectronica) {
              this.facturacionService.esCobro = true;
              this.facturacionService.facturaManual = false;
              // this.router.navigate(["/backoffice/modo-asistido/facturacion-modo-asistido"]);
              this.router.navigate([
                "/backoffice/modo-asistido/facturacion-modo-asistido",
              ]);
            } else {
              this.facturacionService.mensajeAlerta();
            }
          } else {
            return false;
          }
        }
      );
    } else {
      swal("Cobro realizado con éxito", mensaje, "success");
    }
  }

  limpiarFiltros() {
    this.filtro = {
      activo: false,
      campos: {
        id: "",
        fechaDesde: "",
        fechaHasta: "",
        importeTotalDesde: "",
        importeTotalHasta: "",
        cliente: { id: "" },
        formaPago: { id: "" },
        condicionVenta: { id: "" },
      },
    };
  }

  limpiarResto() {
    this.restoCM = undefined;
  }

  cargarCobroMP(mpDatos) {
    return this.http.init(true, "post", "/rest/wsGenerarMpOrdenVentaQR", {
      empresa: this.empresaService.empresa,
      mpDatos: mpDatos,
    });
  }

  generarOrdenPoint(mpDatos) {
    return this.http.init(true, "post", "/rest/wsGenerarMpOrdenVentaPoint", {
      empresa: this.empresaService.empresa,
      mpDatos: mpDatos,
    });
  }

  solicitarLinkDePago(cobro) {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerEnlaceCobroAutogestion",
      {
        empresa: this.empresaService.empresa,
        usuario: this.usuarioService.usuarioLogueado,
        cobro,
      }
    );
  }

  solicitarMpQR() {
    return this.http.init(true, "post", "/rest/wsSolicitarMpQR", {
      empresa: this.empresaService.empresa,
    });
  }

  solicitarTransPosV() {
    return this.http.init(true, "post", "/rest/wsMpTvPrepararPago", {
      empresa: this.empresaService.empresa,
    });
  }

  cancelarTransPoint(datos) {
    return this.http.init(false, "post", "/rest/wsMpCancelarTrans", datos);
  }

  mpConsultarEstadoTrans() {
    return this.http.init(true, "post", "/rest/wsMpConsultarEstadoTrans", {
      empresa: this.empresaService.empresa,
      cobroDetalleMpTrans: this.cobroDetalleMpTrans,
    });
  }

  mpConsultarEstadoTransPosV() {
    return this.http.init(true, "post", "/rest/wsMpConsultarEstadoTrans", {
      empresa: this.empresaService.empresa,
      cobroDetalleMpTrans: this.cobroDetalleMpTransPosV,
    });
  }

  obtenerListadoEntidadBancaria() {
    return this.http.init(true, "get", "/rest/wsObtenerListadoEntidadBancaria");
  }

  obtenerListadoTarjetaEntidad() {
    return this.http.init(true, "get", "/rest/wsSelectorListadoTarjetaEntidad");
  }

  solicitudListadoCobros() {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerListadoCobroPorEmpresa",
      { empresa: this.empresaService.empresa, filtro: this.filtro }
    );
  }

  setCobroActual(event) {
    let input = $(event.target).find("input");
    let cobro:any = input.val();
    this.cobroActual = JSON.parse(cobro);
  }

  completarCobroInstance(cobro) {
    //completar FormaPagoItems
    let formaPagoDatosItem = {
      ...cobro.cobroInstanceComplemento.cobroDetalle[0].detalleComplemento
        .formaPagoDatos.formaPagoDatosItem,
      ...cobro.cobroInstanceComplemento.cobroDetalle[0].detalleComplemento
        .formaPagoDatos.formaPagoDatosComplemento,
    };

    //completar cobroDetalle
    let detalle = {
      ...cobro.cobroInstanceComplemento.cobroDetalle[0].detalle,
      ...cobro.cobroInstanceComplemento.cobroDetalle[0].detalleComplemento,
    };

    //completar cobroInstance complemento
    let cobroInstance = {
      ...cobro.cobroInstance,
      ...cobro.cobroInstanceComplemento,
    };

    //Agregar cobro detalle completo a cobro instance
    cobroInstance.cobroDetalle[0] = detalle;

    //Completar detalle de cada forma de pago
    cobroInstance.cobroDetalle[0].cobroDetalleCheque = formaPagoDatosItem;
    cobroInstance.cobroDetalle[0].cobroDetalleEfectivo = formaPagoDatosItem;
    cobroInstance.cobroDetalle[0].cobroDetalleMp = formaPagoDatosItem;
    cobroInstance.cobroDetalle[0].cobroDetallePagoACuenta = formaPagoDatosItem;
    cobroInstance.cobroDetalle[0].cobroDetallePim = formaPagoDatosItem;
    cobroInstance.cobroDetalle[0].cobroDetallePos = formaPagoDatosItem;
    cobroInstance.cobroDetalle[0].cobroDetalleTp = formaPagoDatosItem;
    cobroInstance.cobroDetalle[0].cobroDetalleVoucher = formaPagoDatosItem;

    return cobroInstance;
  }

  completarCobro(cobro) {
    let list = [];

    for (let cobroDetalle of cobro["cobroInstanceComplemento"][
      "cobroDetalle"
    ]) {
      // cobroDetalle["detalleComplemento"]["formaPagoDatos"][
      //   "formaPagoDatosItem"
      // ]["fechaVencimiento"] = this.utilidadesService.formatearDateString(
      //   cobroDetalle["detalleComplemento"]["formaPagoDatos"][
      //     "formaPagoDatosItem"
      //   ]["fechaVencimiento"]
      // );

      //objetos
      cobroDetalle["detalleComplemento"]["formaPagoDatos"][
        "formaPagoDatosItem"
      ]["chequeEstado"] =
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosComplemento"
        ]["chequeEstado"];
      cobroDetalle["detalleComplemento"]["formaPagoDatos"][
        "formaPagoDatosItem"
      ]["entidadBancaria"] =
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosComplemento"
        ]["entidadBancaria"];
      cobroDetalle["detalleComplemento"]["formaPagoDatos"][
        "formaPagoDatosItem"
      ]["voucherEstado"] =
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosComplemento"
        ]["voucherEstado"];
      cobroDetalle["detalleComplemento"]["formaPagoDatos"][
        "formaPagoDatosItem"
      ]["cobroDetalleMpTrans"] =
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosComplemento"
        ]["cobroDetalleMpTrans"];
      cobroDetalle["detalleComplemento"]["formaPagoDatos"][
        "formaPagoDatosItem"
      ]["mpEstado"] =
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosComplemento"
        ]["mpEstado"];

      cobroDetalle["detalleComplemento"]["formaPagoDatos"][
        "formaPagoDatosItem"
      ]["tarjetaEntidad"] =
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosComplemento"
        ]["tarjetaEntidad"];

      cobroDetalle["detalleComplemento"]["formaPagoDatos"][
        "formaPagoDatosItem"
      ]["tarjetaTipoTrans"] =
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosComplemento"
        ]["tarjetaTipoTrans"];

      //formas de pago
      cobroDetalle["detalle"]["formaPago"] =
        cobroDetalle["detalleComplemento"]["formaPago"];
      cobroDetalle["detalle"]["cobroDetalleCheque"] =
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosItem"
        ];
      cobroDetalle["detalle"]["cobroDetalleVoucher"] =
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosItem"
        ];
      cobroDetalle["detalle"]["cobroDetalleMp"] =
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosItem"
        ];

      cobroDetalle["detalle"]["cobroDetallePos"] =
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosItem"
        ];

      //COMPLETAR PIM

      if (
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosComplemento"
        ]["cobroDetallePimTrans"] != undefined
      ) {
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosComplemento"
        ]["cobroDetallePimTrans"]["pimEstado"] =
          cobroDetalle["detalleComplemento"]["formaPagoDatos"][
            "formaPagoDatosComplemento"
          ]["pimEstado"];

        cobroDetalle["detalle"]["cobroDetallePim"] =
          cobroDetalle["detalleComplemento"]["formaPagoDatos"][
            "formaPagoDatosComplemento"
          ]["cobroDetallePimTrans"];
      }
      list.push(cobroDetalle["detalle"]);
    }

    cobro["cobroInstanceComplemento"]["cobroDetalle"] = list;

    list = [];

    cobro["cobroInstance"]["usuarioUltModif"] =
      cobro["cobroInstanceComplemento"]["usuarioUltModif"];
    cobro["cobroInstance"]["usuarioCreador"] =
      cobro["cobroInstanceComplemento"]["usuarioCreador"];
    cobro["cobroInstance"]["empresa"] =
      cobro["cobroInstanceComplemento"]["empresa"];
    cobro["cobroInstance"][
      "creado"
    ] = this.utilidadesService.formatearDateString(
      cobro["cobroInstance"]["creado"]
    );
    cobro["cobroInstance"]["cliente"] =
      cobro["cobroInstanceComplemento"]["cliente"];
    cobro["cobroInstance"]["caja"] = cobro["cobroInstanceComplemento"]["caja"];
    cobro["cobroInstance"]["cobroDetalle"] =
      cobro["cobroInstanceComplemento"]["cobroDetalle"];

    this.cobroActual = cobro["cobroInstance"];
  }

  completarcobroList(cobroList) {
    let list = [];

    for (let cobro of cobroList) {
      for (let cobroDetalle of cobro["cobroInstanceComplemento"][
        "cobroDetalle"
      ]) {
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosItem"
        ]["fechaVencimiento"] = this.utilidadesService.formatearDateString(
          cobroDetalle["detalleComplemento"]["formaPagoDatos"][
            "formaPagoDatosItem"
          ]["fechaVencimiento"]
        );

        //objetos
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosItem"
        ]["chequeEstado"] =
          cobroDetalle["detalleComplemento"]["formaPagoDatos"][
            "formaPagoDatosComplemento"
          ]["chequeEstado"];
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosItem"
        ]["entidadBancaria"] =
          cobroDetalle["detalleComplemento"]["formaPagoDatos"][
            "formaPagoDatosComplemento"
          ]["entidadBancaria"];
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosItem"
        ]["voucherEstado"] =
          cobroDetalle["detalleComplemento"]["formaPagoDatos"][
            "formaPagoDatosComplemento"
          ]["voucherEstado"];
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosItem"
        ]["cobroDetalleMpTrans"] =
          cobroDetalle["detalleComplemento"]["formaPagoDatos"][
            "formaPagoDatosComplemento"
          ]["cobroDetalleMpTrans"];
        cobroDetalle["detalleComplemento"]["formaPagoDatos"][
          "formaPagoDatosItem"
        ]["mpEstado"] =
          cobroDetalle["detalleComplemento"]["formaPagoDatos"][
            "formaPagoDatosComplemento"
          ]["mpEstado"];

        //COMPLETAR PIM

        if (
          cobroDetalle["detalleComplemento"]["formaPagoDatos"][
            "formaPagoDatosComplemento"
          ]["cobroDetallePimTrans"] != undefined
        ) {
          cobroDetalle["detalleComplemento"]["formaPagoDatos"][
            "formaPagoDatosComplemento"
          ]["cobroDetallePimTrans"]["pimEstado"] =
            cobroDetalle["detalleComplemento"]["formaPagoDatos"][
              "formaPagoDatosComplemento"
            ]["pimEstado"];

          cobroDetalle["detalle"]["cobroDetallePim"] =
            cobroDetalle["detalleComplemento"]["formaPagoDatos"][
              "formaPagoDatosComplemento"
            ]["cobroDetallePimTrans"];
        }
        //formas de pago
        cobroDetalle["detalle"]["formaPago"] =
          cobroDetalle["detalleComplemento"]["formaPago"];
        cobroDetalle["detalle"]["cobroDetalleCheque"] =
          cobroDetalle["detalleComplemento"]["formaPagoDatos"][
            "formaPagoDatosItem"
          ];
        cobroDetalle["detalle"]["cobroDetalleVoucher"] =
          cobroDetalle["detalleComplemento"]["formaPagoDatos"][
            "formaPagoDatosItem"
          ];
        cobroDetalle["detalle"]["cobroDetalleMp"] =
          cobroDetalle["detalleComplemento"]["formaPagoDatos"][
            "formaPagoDatosItem"
          ];
        list.push(cobroDetalle["detalle"]);
      }

      cobro["cobroInstanceComplemento"]["cobroDetalle"] = list;

      list = [];
    }

    for (let cobro of cobroList) {
      cobro["cobroInstance"]["usuarioUltModif"] =
        cobro["cobroInstanceComplemento"]["usuarioUltModif"];
      cobro["cobroInstance"]["usuarioCreador"] =
        cobro["cobroInstanceComplemento"]["usuarioCreador"];
      cobro["cobroInstance"]["empresa"] =
        cobro["cobroInstanceComplemento"]["empresa"];
      // cobro["cobroInstance"][
      //   "creado"
      // ] = this.utilidadesService.formatearDateString(
      //   cobro["cobroInstance"]["creado"]
      // );
      cobro["cobroInstance"]["cliente"] =
        cobro["cobroInstanceComplemento"]["cliente"];
      cobro["cobroInstance"]["caja"] =
        cobro["cobroInstanceComplemento"]["caja"];
      cobro["cobroInstance"]["cobroDetalle"] =
        cobro["cobroInstanceComplemento"]["cobroDetalle"];

      list.push(cobro["cobroInstance"]);
    }

    this.cobrosList = list;
  }

  limpiarCobroNuevo() {
    this.cobroNuevo = new Cobro();
  }

  cobroFacturado(instancia) {
    swal(
      {
        title: "Atención estás por anular un cobro facturado ",
        text:
          "Si quieres descontar la factura asociada, debes de generar una Nota de Crédito contra la misma.",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Sólo Anular",
        confirmButtonClass: "btn-info",
        confirmButtonText: "Anular y generar Nota de crédito",
        closeOnConfirm: true,
      },
      (isConfirm) => {
        if (isConfirm) {
          const dialogRef = this.dialog.open(DialogTemplate, {
            autoFocus: false,
            width: "400px",
            data: { observacion: "" },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (
              instancia.observacion == null ||
              instancia.observacion == undefined
            ) {
              instancia.observacion = "";
            }

            if (result != "" && result != undefined) {
              instancia.observacion += " | Anulación: " + result[0];
            }

            this.eliminar(instancia);

            this.facturacionService.plantilla = {
              comprobanteInstance: instancia.comprobante,
            };
            this.facturacionService.generarNotaCredito().subscribe(
              (data) => {
                if (this.utilidadesService.dataErrorHandler(data)) {
                  swal("Nota de Crédito generada con éxito", "", "success");
                }
              },
              (error) => {
                this.utilidadesService.errorHandler(error);
              }
            );
          });
        } else {
          const dialogRef = this.dialog.open(DialogTemplate, {
            autoFocus: false,
            width: "400px",
            data: { observacion: "" },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (
              instancia.observacion == null ||
              instancia.observacion == undefined
            ) {
              instancia.observacion = "";
            }

            if (result != "" && result != undefined) {
              instancia.observacion += " | Anulación: " + result[0];
            }

            this.eliminar(instancia);
          });
        }
      }
    );
  }

  cobroConPoint(instancia) {
    swal(
      {
        title:
          "Esta acción anulará el cobro con Point pero no devolverá los fondos al cliente",
        text:
          "Los fondos se devuelven usando la app de MercadoPago. Requiere leer nuevamente la tarjeta del cliente con el dispositivo Point.",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Anular",
        closeOnConfirm: !instancia.facturado,
      },
      (isConfirm) => {
        if (isConfirm) {
          if (instancia.facturado) {
            this.cobroFacturado(instancia);
          } else {
            const dialogRef = this.dialog.open(DialogTemplate, {
              autoFocus: false,
              width: "400px",
              data: { observacion: "" },
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (
                instancia.observacion == null ||
                instancia.observacion == undefined
              ) {
                instancia.observacion = "";
              }

              if (result != "" && result != undefined) {
                instancia.observacion += " | Anulación: " + result[0];
              }

              this.eliminar(instancia);
            });
          }
        } else {
          return false;
        }
      }
    );
  }

  consultarEliminar(instancia) {
    let cerrar =
      instancia.facturado || instancia.cobroDetalle[0].formaPago.id == 12
        ? false
        : true;

    swal(
      {
        title: "¿Estás seguro que deseas realizar esta acción?",
        text: "No podrás recuperar el elemento anulado.",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Si, deseo anularlo!",
        closeOnConfirm: cerrar,
      },
      (isConfirm) => {
        if (isConfirm) {
          if (instancia.cobroDetalle[0].formaPago.id == 12) {
            this.cobroConPoint(instancia);
          } else {
            if (instancia.facturado) {
              this.cobroFacturado(instancia);
            } else {
              const dialogRef = this.dialog.open(DialogTemplate, {
                autoFocus: false,
                width: "400px",
                data: { observacion: "" },
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (
                  instancia.observacion == null ||
                  instancia.observacion == undefined
                ) {
                  instancia.observacion = "";
                }

                if (result != "" && result != undefined) {
                  instancia.observacion += " | Anulación: " + result[0];
                }

                this.eliminar(instancia);
              });
            }
          }
        } else {
          return false;
        }
      }
    );
  }

  eliminar(cobro) {
    this.spinnerService.show();
    this.http
      .init(true, "post", "/rest/wsEliminarCobro", {
        empresa: this.empresaService.empresa,
        cobro: cobro,
      })
      .subscribe(
        (data) => {
          this.spinnerService.hide();
          if (
            data["responseCode"] != "200" &&
            data["responseCode"] != "201" &&
            data["responseCode"] != "202"
          ) {
            new swal(
              "Por favor, intenta nuevamente.",
              data["responseMsg"],
              "error"
            );
         
          } else {
            new swal("Cobro anulado con éxito.", "", "success");
            this.events.emit("eliminar");
            this.router.navigate(["/backoffice/listar-cobros"]);
          }
        },

        (error) => {
          this.spinnerService.hide();
          this.utilidadesService.errorHandler(error);
      
        }
      );
  }

  agregar_a_cobro_multiple(cobro) {
    this.cobroNuevo.listadoDetalle[`${this.numero}`] = cobro;
  }

  restar_a_total_cm(importe) {
    this.restoCM = this.restoCM - importe;
    this.restoCM = this.restoCM.toFixed(2);
  }

  preparar_cobro() {
    this.cobroNuevo.cliente.id = +this.cobroNuevo.cliente.id;
    this.cobroNuevo.listadoDetalle["0"][
      "formaPago"
    ] = this.formasDePagoService.formaPagoActual;

    this.cobroNuevo.listadoDetalle["0"]["importe"] = +this.cobroNuevo.listadoDetalle["0"]["importe"];

    this.cobroNuevo.listadoDetalle["0"]["importe"] = this.cobroNuevo.listadoDetalle["0"]["importe"].toFixed(2);
  }

  preparar_cobro_rapido() {
    this.cobroNuevo["importeTotal"] = this.cobroNuevo.listadoDetalle["0"][
      "importe"
    ];
    this.cobroNuevo["observacion"] = this.cobroNuevo.listadoDetalle[
      "0"
    ].itemComplemento["observacion"];
  }
}

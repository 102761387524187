import { Component, ElementRef, ViewChild, Input, OnInit } from "@angular/core";
import { CobroService } from "../../services/cobro.service";

@Component({
  selector: "app-scripthack",
  templateUrl: "./scripthack.component.html",
  styleUrls: ["./scripthack.component.css"]
})
export class ScripthackComponent {
  constructor(public cobroService: CobroService) {}
  @Input()
  src: string;

  @Input()
  type: string;

  @ViewChild("script") script: ElementRef;

  convertToScript() {
    var element = this.script.nativeElement;
    var script = document.createElement("script");
    script.type = this.type ? this.type : "text/javascript";
    script.dataset.publicKey = this.cobroService.publicKey;
    script.dataset.transactionAmount = this.cobroService.transactionAmount;
    if (this.src) {
      script.src = this.src;
    }
    if (element.innerHTML) {
      script.innerHTML = element.innerHTML;
    }
    var parent = element.parentElement;
    parent.parentElement.replaceChild(script, parent);
  }

  ngAfterViewInit() {
    this.convertToScript();
  }
}

import { Injectable } from "@angular/core";

@Injectable()
export class ConfigService {
  protocolHTTP;
  ip;
  puerto;
  baseDir;
  url;
  intervaloNotificaciones;//5 * 60 * 1000

  codifTokenKolekti = "Bearer ";
  modo = "sandbox";

  urlMp = "https://app.cobrosimple.com.ar/autorizar-credenciales-mp";
  urlLogin = "https://app.cobrosimple.com.ar/login";
  urlFormMp;

  constructor() {
    switch (this.modo) {
      case "produccion":
        this.protocolHTTP = "https";
        this.ip = "api.csimple.com.ar";
        this.puerto = "443";
        this.baseDir = "";
        this.urlFormMp = "https://api.csimple.com.ar:443/cobro/mpTvProcesarPago";
        this.intervaloNotificaciones = 1200000;//20 minutos produccion

        break;

      case "sandbox":
        this.protocolHTTP = "https";
        // this.ip = "api.sandbox.kolekti.com.ar";
        this.ip = "api.sandbox.csimpletest.com.ar";
        this.puerto = "443";
        this.baseDir = "";
        // this.urlFormMp =
        //   "https://api.sandbox.kolekti.com.ar:443/cobro/mpTvProcesarPago";
        // this.urlMp =
        //   "https://sandbox.kolektikune.com.ar/autorizar-credenciales-mp";
        this.urlLogin = "https://app.sandbox.csimpletest.com.ar/login";
         this.urlFormMp =
        "https://api.sandbox.csimpletest.com.ar:443/cobro/mpTvProcesarPago";
        this.urlMp =
          "https://app.sandbox.csimpletest.com.ar/autorizar-credenciales-mp";
        this.urlLogin = "https://app.sandbox.csimpletest.com.ar/login";
        this.intervaloNotificaciones = 1200000;

        break;

      case "david"://no se usa
        this.protocolHTTP = "http";
        // this.ip = "168.226.214.161";
        this.ip = "10.0.200.72";
        this.puerto = "8080";
        this.baseDir = "/kolekti";
        this.urlFormMp = "http://192.168.0.102:8080/kolekti/cobro/mpTvProcesarPago";
        this.intervaloNotificaciones = 1000;
        break;
    }

    this.url =
      this.protocolHTTP + "://" + this.ip + ":" + this.puerto + this.baseDir;
  }
}

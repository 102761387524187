import { Injectable, NgZone } from "@angular/core";
import { VerService } from "../services/ver.service";
import { UtilidadesService } from "../services/utilidades.service";
import { Router } from "@angular/router";
import { HttpService } from "../services/http.service";
import { ListarService } from "./listar.service";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ConfigService } from "./config.service";
import { AccesoService } from "./acceso.service";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Observable,Subject } from 'rxjs';

@Injectable()
export class EmpresaService {
  empresa;
  empresaSel;
  empresaList;
  empresaList2;
  datosCargados;
  configPim;
  monotributista;
  responsableInscriptoFE;
  filtro;
  banderaEmpresaSel=false;
  private empresaObserver = new Subject<any[]>();
  public empresaObservable = this.empresaObserver.asObservable();

  constructor(
    private utilidadesService: UtilidadesService,
    private zone: NgZone,
    private spinnerService: Ng4LoadingSpinnerService,
    private http: HttpService,
    private http2: HttpClient,
    private config: ConfigService,
    private verService: VerService,
    private router: Router,
    private listarService: ListarService,
    private accesoService: AccesoService
  ) {
    
    window["empresaService"] = {
      zone: this.zone,
      componentFn: value => this.activarPV(value),
      componentFn2: () => window.history.back(),
      component: this
    };
  }

  solicitudEmpresaTipoResponsableIva() {
    return this.http.init(
      true,
      "get",
      "/rest/wsObtenerListadoTipoResponsableIva"
    );
  }

  solicitudTableroSimplificado(periodo) {
    return this.http.init(true, "post", "/rest/wsEmpresaTableroGeneral", {
      empresa: this.empresa,
      periodo: periodo
    });
  }

  esMonotributista() {
    if (this.empresa.tipoResponsableIva.id == 6) {
      this.monotributista = true;
      return true;
    } else {
      this.monotributista = false;
    }
  }

  esResponsableInscriptoyTieneFacturaElectronica() {
    if (
      this.empresa.tipoResponsableIva.id == 1 &&
      this.empresa.facturaElectronica
    ) {
      this.responsableInscriptoFE = true;
    } else {
      this.responsableInscriptoFE = false;
    }
  }

  actualizarEmpresa(empresa) {
    return this.http.init(true, "post", "/rest/wsActualizarEmpresa", {
      empresa: empresa
    });
  }

  ObtenerDatosEmpresa(empresaObjId) {
    this.datosCargados = false;
    return this.http.init(true, "post", "/rest/wsObtenerDatosEmpresa", {
      empresa: empresaObjId
    });
  }

  activarPopup(instancia) {
    swal(
      {
        title: "¿Estás seguro que deseas realizar esta acción?",
        text: "Ésta acción no se puede revertir .",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Si, deseo activarlo!",
        closeOnConfirm: false
      },
      function(isConfirm) {
        if (isConfirm) {
          window["empresaService"].zone.run(() => {
            window["empresaService"].componentFn(instancia);
          });
        } else {
          return false;
        }
      }
    );
  }

  activarPV(empresaObjId) {
    this.datosCargados = false;    
    this.spinnerService.show();
    this.http.init(true, "post", "/rest/wsRootCsActivarPV", {
      empresa: empresaObjId
    }).subscribe(
      (data) => {
        this.spinnerService.hide();
        if (this.utilidadesService.dataErrorHandler(data)) {
          let empresaAct = this.completarEmpresaInstance(
            data["empresaInstance"],
            data["empresaInstanceComplemento"]
          );
          this.empresaList2 = [];
          new swal(
            "Punto de Venta Activado",
            "Se activó correctamente. Muchas Gracias.",
            "success"
          );
          this.empresaObserver.next(empresaAct);
          //this.router.navigate(["/backoffice/listar-empresas"]);
        }
      },

      (error) => {
        this.utilidadesService.errorHandler(error);
      }
    );
    this.spinnerService.hide();
  }

  enviarDatosFiscales(cuit, clave) {
    return this.http.init(true, "post", "/rest/wsEnviarDatosFiscales", {
      empresa: this.empresa,
      datosFiscales: { cuit: cuit, clave: clave }
    });
  }

  completarEmpresaInstance(empresaInstance, empresaInstanceComplemento) {
    empresaInstance["cuenta"] = empresaInstanceComplemento["cuenta"];
    empresaInstance["empresaConfig"] =
      empresaInstanceComplemento["empresaConfig"];

    empresaInstance["pais"] = empresaInstanceComplemento["pais"];
    empresaInstance["provincia"] = empresaInstanceComplemento["provincia"];
    empresaInstance["tipoDocumento"] =
      empresaInstanceComplemento["tipoDocumento"];
    empresaInstance["tipoResponsableIva"] =
      empresaInstanceComplemento["tipoResponsableIva"];
    empresaInstance["logoComprobanteEncoded"] =
      empresaInstanceComplemento["logoComprobanteEncoded"];
      empresaInstance["urlLogoComprobante"] =
      empresaInstanceComplemento["urlLogoComprobante"];

    return empresaInstance;
  }

  subirImagen(imagen) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: " Bearer " + this.http.token
      })
    };
    return this.http2.post(
      this.config.url + "/rest/wsModificarLogoEmpresa",
      imagen,
      httpOptions
    );
  }
  subirImagen2(imagen) {
    return this.http.init(true, "post", "/rest/wsModificarLogoEmpresa", imagen);
  }

  obtenerEmpresas() {
    return this.http.init(
      true,
      "post",
      "/rest/wsRootCsObtenerListadoEmpresas",
      {
        filtro: this.filtro
      }
    );
  }
  
  limpiarFiltros() {
    this.filtro = {
      activo: false,
      campos: {
        id: "",
        tipoDocumento: { denominacion: "" },
        numeroDocumento: "",
        razonSocial: "",
        tipoResponsableIva: { denominacion: "" },
        facturaElectronica: "",
        empresaConfig: { afipProduccion: "" }
      }
    };
  }
}

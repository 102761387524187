import { Component, OnInit ,Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-simple-form-header',
  templateUrl: './simple-form-header.component.html',
  styleUrls: ['./simple-form-header.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class SimpleFormHeaderComponent implements OnInit {
  @Input() titulo 
  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit,Inject, Input, ViewChild, ElementRef, HostListener, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-dialog-video-ayuda-template',
  templateUrl: './dialog-video-ayuda-template.component.html',
  styleUrls: ['./dialog-video-ayuda-template.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DialogVideoAyudaTemplateComponent implements OnInit {
  urlSeguro = '';
  constructor(
    public dialogRef: MatDialogRef<DialogVideoAyudaTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { 
    if(data){
      if(data.urlAyuda.length == 1){
        this.urlSeguro = data.urlAyuda[0].urlSeguro;
      }
    }
  }

  ngOnInit() {
  }

  seleccionarVideo(urlVideo){
    this.urlSeguro = urlVideo.urlSeguro;
  }

}

import { Component, OnInit } from "@angular/core";
import { fadeInAnimation } from "../../../animations/animations";
import { ScriptService } from "../../../services/script.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginService } from "../../../services/Login.service";
import { UtilidadesService } from "../../../services/utilidades.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";

@Component({
  selector: "app-resetear-clave-b",
  templateUrl: "./resetear-clave-b.component.html",
  styleUrls: ["./resetear-clave-b.component.css"],
  animations: [fadeInAnimation]
})
export class ResetearClaveBComponent implements OnInit {
  animacion;
  password;
  password2;
  usuario;
  codigo;
  dynamicScripts = [
    "./assets/global/plugins/jquery-validation/js/jquery.validate.min.js",
    "./assets/global/plugins/jquery-validation/js/additional-methods.min.js",
    "./assets/components/login/js/login.component.js",
    "./assets/global/plugins/jquery-validation/js/localization/messages_es_AR.js"
  ];

  constructor(
    private scriptService: ScriptService,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    public utilidadesService: UtilidadesService,
    private spinnerService: Ng4LoadingSpinnerService,
    private router: Router
  ) {}

  ngOnInit() {
    //CARGAR SCRIPTS
    new Promise(resolve => {
      this.scriptService.loadScripts(this.dynamicScripts);
      resolve(true);
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.usuario = params["usuario"];
      this.codigo = params["codigo"];
    });
  }

  resetear() {
    if (($("#loginForm") as any).valid()) {
      let reseteoClave = {
        usuario: { claveNueva: this.password, username: this.usuario },
        codigo: this.codigo
      };
      this.spinnerService.show();
      this.loginService.resetearClave(reseteoClave).subscribe(
        data => {
          this.spinnerService.hide();
          if (this.utilidadesService.dataErrorHandler(data)) {
            this.spinnerService.hide();
            new swal(
              "Clave Restablecida",
              "Ya puedes acceder con tu nueva clave. Muchas Gracias.",
              "success"
            );
            this.router.navigate(["/login"]);
          }
        },
        error => {
          this.utilidadesService.errorHandler(error);
        }
      );
    }
  }
}

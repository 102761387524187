import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-item-icon",
  templateUrl: "./item-icon.component.html",
  styleUrls: ["./item-icon.component.css"]
})
export class ItemIconComponent implements OnInit {
  @Output() event = new EventEmitter();

  @Input() icono;
  constructor() {}

  ngOnInit() {}
}

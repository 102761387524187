import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { Login } from "../models/login";
import { ConfigService } from "./config.service";
import { HttpService } from "./http.service";

@Injectable()
export class LoginService {
  url =
    this.config.protocolHTTP +
    "://" +
    this.config.ip +
    ":" +
    this.config.puerto +
    this.config.baseDir;

  cuenta = {
    nombre_contacto: "",
    apellido_contacto: "",
    telefono_contacto: "",
    email_contacto: "",
    clave: "",
    ref_comercial: ""
  };

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private httpService: HttpService
  ) {}

  solicitarReseteoClave(email) {
    return this.httpService.init(
      false,
      "post",
      "/rest/wsSolicitarReseteoClaveUsuarioGeneral",
      {
        usuario: { email: email }
      }
    );
  }

  resetearClave(resetearClave) {
    return this.httpService.init(
      false,
      "post",
      "/rest/wsReseteoClaveUsuarioGeneral",
      resetearClave
    );
  }

  autogestion(user, clave, id) {
    return this.httpService.init(
      false,
      "post",
      "/rest/wsValidarEnlaceCobroAutogestion",
      { usuario: { loginUsuario: user, loginClave: clave }, link: { id: +id } }
    );
  }

  registrar(cuenta) {
    return this.httpService.init(
      false,
      "post",
      "/rest/wsGenerarCuentaSimplificada",
      { cuenta }
    );
  }

  login(username, password): Observable<Login> {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    return this.http.post<Login>(
      this.url + "/rest/login",
      { loginUsuario: username, loginClave: password },
      httpOptions
    );
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure

      // TODO: better job of transforming error for user consumption

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

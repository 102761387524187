import { EventEmitter, Injectable } from "@angular/core";
import { AccesoService } from "./acceso.service";
import { HttpService } from "./http.service";
import { EmpresaService } from "./empresa.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class FacturacionService {
  tiposComprobantes = [];
  tiposComprobantesListar = [];
  usuarioListar = [];
  cliente;
  tipoComprobante;
  usuario;
  iva;
  plantilla;
  facturacionVer;
  filtro;
  comprobanteList;
  comprobanteActual;
  importeTotal;
  esCobro = false;
  esCliente = false;
  eligioCliente;
  facturaManual;
  events = new EventEmitter();

  constructor(
    private accesoService: AccesoService,
    private http: HttpService,
    private empresaService: EmpresaService,
    private router: Router
  ) {}

  tienePermisoCrearComprobante() {}

  solicitarTipoComprobante() {
    //return this.http.init(true, "post", "/rest/wsObtenerListadoTipoCbteAsoc", {
    return this.http.init(true, "post", "/rest/wsObtenerListadoTipoCbteAsocSimplificado", {
      empresa: this.empresaService.empresa
    });
  }

  solicitarTipoComprobanteListar() {
    return this.http.init(true, "get", "/rest/wsObtenerListadoTipoComprobante");
  }

  obtenerDatosComprobante(comprobante) {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerDatosComprobantePorEmpresa",
      {
        empresa: this.empresaService.empresa,
        comprobante: comprobante
      }
    );
  }

  enviarEmailComprobante(comprobante, email) {
    return this.http.init(true, "post", "/rest/wsEnviarEmailComprobante", {
      empresa: this.empresaService.empresa,
      comprobante: comprobante,
      clienteCasillaEmail: email
    });
  }

  generarNotaCredito() {
    return this.http.init(true, "post", "/rest/wsAnularFacturaCobro", {
      empresa: this.empresaService.empresa,
      comprobante: this.plantilla.comprobanteInstance?this.plantilla.comprobanteInstance:this.plantilla.comprobanteRespuestaJson
    });
  }

  autorizar() {
    return this.http.init(true, "post", "/rest/wsAutorizarComprobante", {
      empresa: this.empresaService.empresa,
      comprobante: this.plantilla.comprobanteInstance?this.plantilla.comprobanteInstance:this.plantilla.comprobanteRespuestaJson
    });
  }

  obtenerComprobanteNoAutorizado(){
    return this.http.init(true, "post", "/rest/wsObtenerComprobanteNoAutorizado", {
      empresa: this.empresaService.empresa
    });

  }

  limpiarFiltros() {
    this.filtro = {
      activo: false,
      campos: {
        id: "",
        fechaDesde: "",
        fechaHasta: "",
        importeTotalDesde: "",
        importeTotalHasta: "",
        cliente: { id: "" },
        tipoComprobante: { id: "" },
        condicionVenta: {id: ""},
        usuario: {id: ""}
      }
    };
  }

  obtenerComprobantes() {
    return this.http.init(
      true,
      "post",
      "/rest/wsObtenerListadoComprobantePorEmpresa",
      {
        empresa: this.empresaService.empresa,
        filtro: this.filtro
      }
    );
  }

  mensajeAlerta() {
    swal(
      {
        title: "Aún no tienes habilitada Factura Electrónica",

        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        // confirmButtonClass: "btn-danger",
        confirmButtonText: "Habilitar",
        closeOnConfirm: true
      },
      isConfirm => {
        if (isConfirm) {
          this.router.navigate(["/backoffice/modal-facturacion/config"]);
        } else {
          return false;
        }
      }
    );

    // this.dialog.open(ModalFacturacionComponent, {
    //   width: "650px",
    //   data: {}
    // });
  }

  facturarCobro(cobro, comprobante) {
    return this.http.init(true, "post", "/rest/wsFacturarCobro", {
      empresa: this.empresaService.empresa,
      cobro: cobro,
      comprobante: comprobante
    });
  }

  setComprobanteActual(event) {
    let input = $(event.target).find("input");
    let comprobante:any = input.val();
    let idComprobante:any = comprobante.id;

    this.plantilla = JSON.parse(comprobante);
  }

  booleanNotaCredito() {
    let comprobanteList = [];
    for (let comprobante of this.comprobanteList) {
      if (
        comprobante.comprobanteInstance.tipoComprobante.id == 20 ||
        comprobante.comprobanteInstance.tipoComprobante.id == 3 ||
        comprobante.comprobanteInstance.tipoComprobante.id == 8 ||
        comprobante.comprobanteInstance.tipoComprobante.id == 13
      ) {
        comprobante.notaCredito = true;
      }
      comprobanteList.push(comprobante);
    }
    this.comprobanteList = comprobanteList;
  }
}

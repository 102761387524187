import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AccesoService } from './services/acceso.service'
import { Router }  from '@angular/router';



@Injectable()
export class AccesoGuard implements CanActivate {

  constructor (private accesoService : AccesoService , private router : Router ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    
    if (this.accesoService.estaLogueado()) {return true} else {  this.router.navigate(['/login']) ; return false}
  }
}

import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "app-volver-btn",
  templateUrl: "./volver-btn.component.html",
  styleUrls: ["./volver-btn.component.css"]
})
export class VolverBtnComponent implements OnInit {
  constructor(public location: Location) {}

  ngOnInit() {}
}

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-time-picker",
  templateUrl: "./time-picker.component.html",
  styleUrls: ["./time-picker.component.css"]
})
export class TimePickerComponent implements OnInit {
  modal;
  hora;
  minuto;
  horaMinuto;

  @Output() onClose = new EventEmitter();

  constructor() {}

  ngOnInit() {
    var d = new Date();
    this.hora = d.getHours();
    this.minuto = d.getMinutes();
  }

  ngAfterViewInit(): void {
    // Get the modal
    this.modal = document.getElementById("myModal");

    // window.onclick = event => {
    //   if (event.target == this.modal) {
    //     this.cerrarModal();
    //   }
    // };
  }

  cargarHora() {
    if (this.hora > 23 || this.minuto > 59) {
      new swal(
        "Formato de hora incorrecto",
        "Por favor, intente nuevamente",
        "error"
      );
    } else if (this.hora === null || this.minuto === null) {
      new swal(
        "Hay campos sin completar",
        "Por favor, complete todos los campos para continuar",
        "error"
      );
    } else {
      if (this.minuto.toString().length == 1) {
        this.minuto = "0" + this.minuto;
      }

      if (this.hora.toString().length == 1) {
        this.hora = "0" + this.hora;
      }

      this.horaMinuto = this.hora + ":" + this.minuto;
      this.onClose.emit();
      this.modal.style.display = "none";
    }
  }

  abrirModal() {
    this.modal.style.display = "block";
  }

  cerrarModal() {
    this.horaMinuto = undefined;

    this.onClose.emit();
    this.modal.style.display = "none";
  }
}

import { Component, OnInit } from "@angular/core";
import { fadeInAnimation2 } from "../../animations/animations";

@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.css"],
  animations: [fadeInAnimation2]
})
export class PopupComponent implements OnInit {
  state = true;
  constructor() {}

  ngOnInit() {}

  cerrarPopup() {
    $("#cerrarPopup").click();
  }
}

import { Injectable } from "@angular/core";
import { CajaService } from "./caja.service";

@Injectable({
  providedIn: "root"
})
export class CalculosCierreCajaService {
  ver = false;

  constructor(private cajaService: CajaService) {}

  calcularTotales() {
    this.calcularTotalesFormasPago();
    this.calcularTotalMovimientosCajaIn();
    this.calcularTotalMovimientosCajaOut();

    this.calcularTotalVentas();

    this.calcularSaldoTotalExistencia();
    this.calcularSaldoContraparte();
    this.calcularSaldoCierre();
  }

  calcularTotalesFormasPago() {
    this.cajaService.totalesCierreCaja.totalEfectivo =
      this.cajaService.totalesCierreCaja.cobroEfectivo +
      this.cajaService.totalesCierreCaja.cajaEfectivoIn -
      this.cajaService.totalesCierreCaja.cajaEfectivoOut;

    this.cajaService.totalesCierreCaja.totalCheque =
      this.cajaService.totalesCierreCaja.cobroCheque +
      this.cajaService.totalesCierreCaja.cajaChequeIn -
      this.cajaService.totalesCierreCaja.cajaChequeOut;

    this.cajaService.totalesCierreCaja.totalVoucher =
      this.cajaService.totalesCierreCaja.cobroVoucher +
      this.cajaService.totalesCierreCaja.cajaVoucherIn -
      this.cajaService.totalesCierreCaja.cajaVoucherOut;

    this.cajaService.totalesCierreCaja.totalMpQR =
      this.cajaService.totalesCierreCaja.cobroMpQR +
      this.cajaService.totalesCierreCaja.cajaMpQRIn -
      this.cajaService.totalesCierreCaja.cajaMpQROut;

    this.cajaService.totalesCierreCaja.totalPosV =
      this.cajaService.totalesCierreCaja.cobroPosV +
      this.cajaService.totalesCierreCaja.cajaPosVIn -
      this.cajaService.totalesCierreCaja.cajaPosVOut;

    this.cajaService.totalesCierreCaja.totalPim =
      this.cajaService.totalesCierreCaja.cobroPim +
      this.cajaService.totalesCierreCaja.cajaPimIn -
      this.cajaService.totalesCierreCaja.cajaPimOut;

    this.cajaService.totalesCierreCaja.totalPoint =
      this.cajaService.totalesCierreCaja.cobroPoint +
      this.cajaService.totalesCierreCaja.cajaPointIn -
      this.cajaService.totalesCierreCaja.cajaPointOut;

    this.cajaService.totalesCierreCaja.totalCuenta = this.cajaService.totalesCierreCaja.cobroPagoACuenta;
  }

  calcularTotalMovimientosCajaIn() {
    this.cajaService.totalesCierreCaja.totalIngresos =
      this.cajaService.totalesCierreCaja.cajaEfectivoIn +
      this.cajaService.totalesCierreCaja.cajaChequeIn +
      this.cajaService.totalesCierreCaja.cajaVoucherIn +
      this.cajaService.totalesCierreCaja.cajaMpQRIn +
      this.cajaService.totalesCierreCaja.cajaPosVIn +
      this.cajaService.totalesCierreCaja.cajaPimIn +
      this.cajaService.totalesCierreCaja.cajaPointIn;
  }

  calcularTotalMovimientosCajaOut() {
    this.cajaService.totalesCierreCaja.totalEgresos =
      this.cajaService.totalesCierreCaja.cajaEfectivoOut +
      this.cajaService.totalesCierreCaja.cajaChequeOut +
      this.cajaService.totalesCierreCaja.cajaVoucherOut +
      this.cajaService.totalesCierreCaja.cajaMpQROut +
      this.cajaService.totalesCierreCaja.cajaPosVOut +
      this.cajaService.totalesCierreCaja.cajaPimOut +
      this.cajaService.totalesCierreCaja.cajaPointOut;
  }

  calcularTotalVentas() {
    this.cajaService.totalesCierreCaja.totalVentas =
      this.cajaService.totalesCierreCaja.cobroEfectivo +
      this.cajaService.totalesCierreCaja.cobroCheque +
      this.cajaService.totalesCierreCaja.cobroMpQR +
      this.cajaService.totalesCierreCaja.cobroPim +
      this.cajaService.totalesCierreCaja.cobroPosV +
      this.cajaService.totalesCierreCaja.cobroVoucher +
      this.cajaService.totalesCierreCaja.cobroPoint +
      this.cajaService.totalesCierreCaja.cobroPagoACuenta;

    this.cajaService.totalesCierreCaja.totalVentas = this.cajaService.totalesCierreCaja.totalVentas.toFixed(
      2
    );
  }

  calcularSaldoTotalExistencia() {
    this.cajaService.totalesCierreCaja.totalSaldoExistente =
      +this.cajaService.totalesCierreCaja.totalVentasMin +
      this.cajaService.totalesCierreCaja.totalIngresos +
      this.cajaService.totalesCierreCaja.totalDeducciones +
      this.cajaService.totalesCierreCaja.totalFacturasSinCobrar -
      this.cajaService.totalesCierreCaja.totalEgresos;
  }

  calcularSaldoContraparte() {
    this.cajaService.totalesCierreCaja.totalSaldoContraparte =
      this.cajaService.totalesCierreCaja.totalVentas +
      this.cajaService.totalesCierreCaja.totalIngresos +
      this.cajaService.totalesCierreCaja.totalAdicionales -
      this.cajaService.totalesCierreCaja.totalEgresos;
  }

  calcularSaldoCierre() {
    this.cajaService.totalesCierreCaja.totalSaldoCierre =
      this.cajaService.totalesCierreCaja.totalSaldoExistente -
      this.cajaService.totalesCierreCaja.totalVentas;
  }
}

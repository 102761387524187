import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-simple-page-form',
  templateUrl: './simple-page-form.component.html',
  styleUrls: ['./simple-page-form.component.css']
})
export class SimplePageFormComponent implements OnInit {

  

  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import { UtilidadesService } from './utilidades.service'




@Injectable()
export class VerService {
  titulo: string = ""
  invalidas = [null, "", false, undefined, 0]
  keyAndValueList = []
  nombreSubAtributoList = []
  modalKeyAndValueList = []



  constructor(private utilidadesService: UtilidadesService, private router: Router) { }


  cleanShow() {

    this.titulo = ""
    this.keyAndValueList = []
    this.nombreSubAtributoList = []
    this.modalKeyAndValueList = []

  }

  crearVista(titulo, objeto, orden, fechas, nombreAtributoYeditado, valorAtributoYeditado, nombreSubAtributoList) {
    this.cleanShow()
    this.cargarTitulo(titulo)
    this.cargarNombreAtributoSubObjetoList(nombreSubAtributoList)
    this.cargarCeldas(objeto)
    this.keyAndValueList = this.ordernar(orden, this.keyAndValueList)
    this.modificarCeldas(fechas, nombreAtributoYeditado, valorAtributoYeditado, this.keyAndValueList)
    this.irAlComponenteVerGeneral()


  }


  ///////////////////////////////////////////////////////////////////////METODOS


  cargarTitulo(titulo) {
    this.titulo = titulo
  }

  cargarCeldas(objeto) {
    this.keyAndValueList = Object.entries(objeto)
  }

  modificarCeldas( fechas, nombreAtributoYeditado, valorAtributoYeditado, keyAndValueList ) {
    for (let keyAndValue of keyAndValueList) {
      this.modificarFechas(keyAndValue,fechas)
      this.modificarNombreAtributo(keyAndValue, nombreAtributoYeditado)
      this.modificarValorAtributo(keyAndValue, valorAtributoYeditado)
      this.primeraLetraMayucula(keyAndValue)
    }
  }



  esconderExcepciones(keyAndValue, excepciones) {
    for (let excepcion of excepciones) {
      if (keyAndValue[0] == excepcion) { this.keyAndValueList = this.keyAndValueList.filter(e => e != keyAndValue) }
    }
  }


  modificarNombreAtributo(keyAndValue, nombreAtributoYeditado) {
    for (let nombre of nombreAtributoYeditado) {
      if (keyAndValue[0] == nombre[0]) {
        keyAndValue[0] = nombre[1]
      }
    }
  }

  modificarValorAtributo(keyAndValue, valorAtributoYeditado){

    for (let value of valorAtributoYeditado) {
      if (keyAndValue[1] === value[0]) { keyAndValue[1] = value[1] }
    }

  }

  modificarFechas(keyAndValue,fechas) {
    for (let fecha of fechas) {
     if (keyAndValue[0] == fecha){
      keyAndValue[1] =  this.utilidadesService.formatearDateString(keyAndValue[1]) 
    }
  }
  }

  primeraLetraMayucula(keyAndValue){
   keyAndValue[0] = capitalizeFirstLetter(keyAndValue[0])
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }  
  }

  cargarNombreAtributoSubObjetoList(nombreSubAtributoList){
    this.nombreSubAtributoList = nombreSubAtributoList
  }
 
  irAlComponenteVerGeneral() {
    this.router.navigate(["/backoffice/administracion/ver"])
  }

  ordernar(orden,keyAndValueList){
    let keyAndValueListOrdenado = []

    for (let element of orden){
    for(let keyAndValue of keyAndValueList){
        
          if(keyAndValue[0] == element )
          keyAndValueListOrdenado.push( keyAndValue ) 
        }

    }

   return keyAndValueListOrdenado
  }

  
  crearVistaModal(objeto, fechas, nombreAtributoYeditado, valorAtributoYeditado, orden) {
    this.modalKeyAndValueList = []
    this.modalKeyAndValueList = Object.entries(objeto)
    this.modalKeyAndValueList = this.ordernar(orden, this.modalKeyAndValueList)
    for (let keyAndValue of this.modalKeyAndValueList){
     
    
      this.modificarFechas(keyAndValue,fechas)
      this.modificarNombreAtributo(keyAndValue, nombreAtributoYeditado)
      this.modificarValorAtributo(keyAndValue, valorAtributoYeditado)
      this.primeraLetraMayucula(keyAndValue)

   }
  }


}


















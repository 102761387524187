import { Component, OnInit, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CobroService } from "../../services/cobro.service";
import { ConfigService } from "../../services/config.service";
import { UtilidadesService } from "../../services/utilidades.service";

@Component({
  selector: "app-modal-post-virtual",
  templateUrl: "./modal-post-virtual.component.html",
  styleUrls: ["./modal-post-virtual.component.css"]
})
export class ModalPostVirtualComponent implements OnInit {
  data = (<HTMLInputElement>parent.document.getElementById("data")).value;
  cargando = true;

  urlMp;
  constructor(
    public elementRef: ElementRef,
    public cobroService: CobroService,
    private route: ActivatedRoute,
    public configService: ConfigService,
    public utilidadesService: UtilidadesService
  ) {}

  ngOnInit() {
    // this.data = {
    //   usuarioId: 1,
    //   empresaId: 2,
    //   importe: 3,
    //   email: 3,
    //   cobroDetalleMpTransId: 5
    // };
    //this.utilidadesService.loading = false;
    this.utilidadesService.setCargando(false);
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.urlMp = "/assets/post.php";
    } else {
      this.urlMp = this.configService.urlFormMp;
    }

    var iframe = document.getElementsByTagName("iframe");

    $("iframe").bind("onClose", function() {
      alert('User clicked on "foo."');
    });

    this.data = JSON.parse(this.data);

    this.cobroService.publicKey = this.data["publicKey"];
    this.cobroService.transactionAmount = this.data["importe"];

    var intervalo = setInterval(() => {
      var myElem = $(".mercadopago-button").val();
      if (myElem != undefined) {
        $(".mercadopago-button").click(), clearInterval(intervalo);
      }
    }, 250);
  }

  submitForm() {
    $("#myForm").submit();
  }

  cerrar() {
    window.close();
    parent.document.getElementById("data").click();
  }

  cambiarMensaje() {
    setTimeout(() => {
      this.cargando = false;
      $("#quitar").click();
    }, 7000);
  }
}

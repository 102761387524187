import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class AutogestionService {
  importe;
  idLink;
  cliente;
  returnUrl;
  externalRef;
  urlExito;
  urlError;

  constructor() {}

  setLinks() {
    this.urlExito =
      this.returnUrl +
      `?importeTotal=${this.importe}&resultado=exito&externalRef=${
        this.externalRef
      }&idSolicitud=${this.idLink}`;
    this.urlError =
      this.returnUrl +
      `?importeTotal=${this.importe}&resultado=error&externalRef=${
        this.externalRef
      }&idSolicitud=${this.idLink}`;
  }
}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-modal-post-virtual2",
  templateUrl: "./modal-post-virtual2.component.html",
  styleUrls: ["./modal-post-virtual2.component.css"]
})
export class ModalPostVirtual2Component implements OnInit {
  constructor() {}

  ngOnInit() {
 
    parent.document.getElementById("posVirtual2").click();
  }
}

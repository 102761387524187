import { Component, OnInit } from "@angular/core";
import { AccesoService } from "../../services/acceso.service";
import { Router } from "@angular/router";
import { UsuarioService } from "../../services/usuario.service";
import { UtilidadesService } from "../../services/utilidades.service";
import { LoginService } from "../../services/Login.service";
import { HttpService } from "../../services/http.service";
import { EmpresaService } from "../../services/empresa.service";

@Component({
  selector: "app-cargar-empresa",
  templateUrl: "./cargar-empresa.component.html",
  styleUrls: ["./cargar-empresa.component.css"]
})
export class CargarEmpresaComponent implements OnInit {
  descripcion = "Será redirigido en breve. Gracias";

  constructor(
    private httpService: HttpService,
    public utilidadesService: UtilidadesService,
    private route: Router,
    private accesoService: AccesoService,
    private usuarioService: UsuarioService,
    private loginService: LoginService,
    public empresaService: EmpresaService
  ) {}

  ngOnInit() {
    this.utilidadesService.notificaciones = [];
    this.setearEmpresaActual();
  }

  setearEmpresaActual() {
    this.accesoService
      .solicitarPermisosEmpresa(this.empresaService.empresa)
      .subscribe(
        data => {
          if (this.utilidadesService.dataErrorHandler(data)) {
            this.accesoService.permisosLogin = data["roles"];
            //eliminar el intervalo generado para las notificaciones que no corresponden al id empresa seleccionado              
            if(localStorage.getItem('intervalID')){
                var id = JSON.parse(localStorage.getItem('intervalID'));
                clearInterval(id);
              }
              //eliminar los localstorage generados por el punto de venta anterior
              localStorage.removeItem('sizeTicket');
              localStorage.removeItem('paisSel');
              localStorage.removeItem('provinciaSel');
              localStorage.removeItem('notificaciones');
              localStorage.removeItem('intervalID');
              localStorage.removeItem('facturacionFormaPago');
              localStorage.removeItem('importeAutomatico');
              localStorage.removeItem('concepto');
              localStorage.removeItem('iva');
              sessionStorage.removeItem('filtroFacturacion');
            if (
              this.accesoService.permisosLogin.includes(
                "ROLE_PERMISSION_ASAT_Generic-Cajero"
              )
            ) {
              this.route.navigate(["/backoffice/menu-inicial"]);
            } else {
              this.route.navigate(["/backoffice/tablero-general"]);
            }
          }
        },
        error => {
          this.utilidadesService.errorHandler(error);
          if (
            this.accesoService.permisosLogin.includes(
              "ROLE_PERMISSION_ASAT_Generic-Cajero"
            )
          ) {
            this.route.navigate(["/backoffice/menu-inicial"]);
          } else {
            this.route.navigate(["/backoffice/tablero-general"]);
          }
        }
      );
  }
}

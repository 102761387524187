import { Injectable } from "@angular/core";
import { EmpresaService } from "../empresa.service";
import { HttpService } from "../http.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class PimService {
  cobroDetallePimTransInstance;
  dni;

  constructor(
    private empresaService: EmpresaService,
    private http: HttpService,
    private router: Router
  ) {}

  validarCredencialesPim() {
    return this.http.init(true, "post", "/rest/wsValidarCredencialesPim", {
      empresa: this.empresaService.empresa
    });
  }

  solicitarCobroPim(pimDatos) {
    return this.http.init(true, "post", "/rest/wsSolicitarCobroPIM", {
      empresa: this.empresaService.empresa,
      pimDatos: pimDatos
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-form-inputs',
  templateUrl: './simple-form-inputs.component.html',
  styleUrls: ['./simple-form-inputs.component.css']
})
export class SimpleFormInputsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

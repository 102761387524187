import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ScriptService } from "../../services/script.service";

@Component({
  selector: "app-registro-cuenta",
  templateUrl: "./registro-cuenta.component.html",
  styleUrls: ["./registro-cuenta.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class RegistroCuentaComponent implements OnInit {
  tutorial;

  dynamicScripts = [
    "../assets/global/plugins/bootstrap-modal/js/bootstrap-modalmanager.js",
    "../assets/global/plugins/bootstrap-modal/js/bootstrap-modal.js",
    "../assets/pages/scripts/ui-extended-modals.min.js"
  ];

  constructor(private scriptService: ScriptService) {}

  ngOnInit() {
    //CARGAR SCRIPTS
    new Promise(resolve => {
      this.scriptService.loadScripts(this.dynamicScripts);
      resolve(true);
    });
  }

  ngOnDestroy(): void {
    this.scriptService.destroyScripts(this.dynamicScripts);
  }
}

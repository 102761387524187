import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-simple-form-buttons',
  templateUrl: './simple-form-buttons.component.html',
  styleUrls: ['./simple-form-buttons.component.css']
})
export class SimpleFormButtonsComponent implements OnInit {

  nameModificado 

  @Input() set variable(name: string) {
    this.nameModificado = name + " lalala"
  }

     get variable() {
    return this.nameModificado
    }
 
 


  @Input() propiedad
  
  
  constructor() { }

  ngOnInit() {
  }


}

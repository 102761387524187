import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormasDePagoService } from "../../../../../../../services/formas-de-pago.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { UtilidadesService } from "../../../../../../../services/utilidades.service";
import { ConfigService } from "../../../../../../../services/config.service";

@Component({
  selector: "app-autorizar-credenciales-mp",
  templateUrl: "./autorizar-credenciales-mp.component.html",
  styleUrls: ["./autorizar-credenciales-mp.component.css"]
})
export class AutorizarCredencialesMpComponent implements OnInit {
  code;
  datos;
  cargando;
  constructor(
    private activatedRoute: ActivatedRoute,
    private formasDePagoService: FormasDePagoService,
    private spinnerService: Ng4LoadingSpinnerService,
    public utilidadesService: UtilidadesService,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    this.cargando = true;
    //this.utilidadesService.loading = false;
    this.utilidadesService.setCargando(false);
    this.activatedRoute.queryParams.subscribe(params => {
      this.code = params["code"];
      this.datos = params["datos"];
    });
    this.autorizarCredenciales();
  }

  reloguear() {
    window.location.href = this.configService.urlLogin;
  }

  autorizarCredenciales() {
    this.formasDePagoService
      .activarCredencialesMp(this.code, this.datos)
      .subscribe(
        data => {
          this.cargando = false;
          this.spinnerService.hide();
          if (this.utilidadesService.dataErrorHandler(data)) {
            new swal(
              "Credenciales Autorizadas",
              "Ya podés cobrar con Mercadopago QR y pos virtual",
              "success"
            );
          }
        },
        error => {
          this.cargando = false;
          this.spinnerService.hide();
          this.utilidadesService.errorHandler(error);
        }
      );
  }
}

import { Component, OnInit, Input } from "@angular/core";
import * as jspdf from "jspdf";
import * as html2canvas from "html2canvas";
import { UtilidadesService } from "../../services/utilidades.service";

@Component({
  selector: "app-screen-capture",
  templateUrl: "./screen-capture.component.html",
  styleUrls: ["./screen-capture.component.css"]
})
export class ScreenCaptureComponent implements OnInit {
  @Input() nombre = "Imprimir";
  @Input() id;

  constructor(public utilidadesService: UtilidadesService) {}

  ngOnInit() {
    if (this.id) {
      this.nombre =
        this.nombre +
        "_ID_" +
        this.id +
        "_fecha_" +
        this.utilidadesService.formatearDateString(new Date());
    } else {
      this.nombre =
        this.nombre +
        "_fecha_" +
        this.utilidadesService.formatearDateString(new Date());
    }
  }

  public captureScreen() {
    swal("Se está iniciando la descarga", "Aguarde por favor", "info");
    // this.comprobanteDescargado = true;

    var data = document.getElementById("screen");
    html2canvas(data).then(canvas => {
      var imgData = canvas.toDataURL("image/jpg");

      var imgWidth = 100;
      var pageHeight = 295;

      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jspdf("p", "mm");
      var position = 0;

      doc.addImage(imgData, "JPG", 55, 5, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "JPG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      doc.save(this.nombre);
    });
  }
}

import { Component, OnInit, Input } from "@angular/core";
import { UtilidadesService } from "../../services/utilidades.service";

@Component({
  selector: "app-iframe",
  templateUrl: "./iframe.component.html",
  styleUrls: ["./iframe.component.css"]
})
export class IframeComponent implements OnInit {
  @Input() video;

  constructor(public utilidadesService: UtilidadesService) {}

  ngOnInit() {}

  cerrarVideo() {
    $("#cerrarVideo").click();
  }
}

import { Component, OnInit } from "@angular/core";
import { UtilidadesService } from "../../services/utilidades.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-modal-bienvenida",
  templateUrl: "./modal-bienvenida.component.html",
  styleUrls: ["./modal-bienvenida.component.css"],
})
export class ModalBienvenidaComponent implements OnInit {
  constructor(
    public utilidadesService: UtilidadesService,
    public router: Router
  ) {}

  ngOnInit() {}

  facturacion() {
    this.router.navigate(["/backoffice/modal-facturacion/config"]);
    this.cerrar();
  }

  cerrar() {
    this.utilidadesService.bienvenida = false;
  }
}

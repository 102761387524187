import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-basic-input",
  templateUrl: "./basic-input.component.html",
  styleUrls: ["./basic-input.component.css"]
})
export class BasicInputComponent implements OnInit {
  inputFormControl;
  @ViewChild("input") input;
  @Input() required;
  @Input() type;
  @Input() hint;
  @Input() default = "";
  @Input() placeholder;
  @Input() importe;
  @Input() id;
  @Input() minlength;
  @Input() autofocus;
  @Input() CUIT;
  eye;
  visibility = "visibility_off";
  value;
  valid;

  constructor() {}

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (this.autofocus) {
      this.focus();
    }
  }

  ngOnInit() {
    let validatorsList = [];

    if (this.required) {
      validatorsList = [Validators.required];
    }
    switch (this.type) {
      case "email":
        validatorsList = [...validatorsList, Validators.email];

        this.hint = this.hint ? this.hint : "Ingresá un email válido";
        break;

      case "password":
        this.eye = true;
        this.minlength = "6";

        this.hint = this.hint
          ? this.hint
          : "Ingresá una clave de al menos 6 caracteres";

        break;
    }

    this.inputFormControl = new FormControl(this.default, validatorsList);

    if (this.importe) {
      this.type = "number";
      this.id = "importe";
      this.placeholder = this.placeholder ? this.placeholder : "importe";
    }

    this.valueChange();
  }

  valueChange() {
    this.value = this.inputFormControl.value;
    this.valid = this.inputFormControl.valid;
    if (this.type == "email" && this.inputFormControl.value) {
      this.inputFormControl.value = this.inputFormControl.value.trim();
    }
  }

  focus() {
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 100);
  }
}

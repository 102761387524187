export class CuentaNueva {
  empresaRazonSocial;
  empresaDenominacionComercial;
  empresaCuit;
  contactoNombre;
  contactoApellido;
  contactoTelefonoMovil;
  contactoEmail;
  id;
  idComercial;
}

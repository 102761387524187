import { Injectable } from "@angular/core";
import { CobroService } from "./cobro.service";
import { UtilidadesService } from "./utilidades.service";
import { FormasDePagoService } from "./formas-de-pago.service";
import { Router } from "@angular/router";
import { DetalleCobroMultiple } from "../models/cobro";

@Injectable()
export class CobroMultipleService {
  llego_a_resumen;

  constructor(
    private cobroService: CobroService,
    private utilidadesService: UtilidadesService,
    private formaDePagoService: FormasDePagoService,
    private router: Router
  ) {}

  cargar_cobro() {
    let cobro = this.cobroService.cobroNuevo.listadoDetalle["0"];

    cobro["importe"] = +cobro["importe"];
    cobro["importe"] = cobro["importe"].toFixed(2);
    cobro["formaPago"] = this.formaDePagoService.formaPagoActual;

    this.cobroService.agregar_a_cobro_multiple(cobro);

    this.cobroService.restar_a_total_cm(cobro["importe"]);

    this.agregar_a_tabla(cobro);

    if (this.cobroService.restoCM == 0) {
      this.router.navigate(["backoffice/resumen-cobro-multiple"]);
    } else {
      this.prepara_proximo_cobro();
      this.actualizar_barra();
      this.router.navigate(["backoffice/cobro-multiple"]);
    }
  }

  agregar_a_tabla(cobro) {
    this.cobroService.multiCobroList.push({
      numero: this.cobroService.numero,
      formaPago: this.formaDePagoService.formaPagoActual.nombre,
      importe: cobro["importe"],
      objeto: cobro
    });
  }

  actualizar_barra() {
    this.cobroService.barra =
      100 -
      (this.cobroService.restoCM * 100) /
        this.cobroService.cobroNuevo["importeTotal"] +
      "%";
  }

  prepara_proximo_cobro() { 
    this.formaDePagoService.formaPagoActual = {};
    this.cobroService.numero++;
    this.cobroService.cobroNuevo.listadoDetalle["0"] = new DetalleCobroMultiple()
  }

  cancelar() {
    this.formaDePagoService.formaPagoActual = { logoUrl: "" };
    this.cobroService.cobroNuevo.listadoDetalle["0"] = {
      formaPago: {},
      itemComplemento: { entidadBancaria: {} }
    };
    this.router.navigate(["backoffice/cobro-multiple"]);
    return false;
  }
}

import { Directive } from "@angular/core";
import {
  Validator,
  NG_VALIDATORS,
  ValidatorFn,
  FormControl,
} from "@angular/forms";

@Directive({
  selector: "[appCuitvalidator]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useClass: CuitvalidatorDirective,
      multi: true,
    },
  ],
})
export class CuitvalidatorDirective implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = this.cuitValidator();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }

  cuitValidator(): ValidatorFn {
    return (control: FormControl) => {
      if (control.value != null && control.value !== "") {
        let isValid = this.validarCuit(control.value);
        if (isValid) {
          return null;
        } else {
          return {
            cuitvalidator: { valid: false },
          };
        }
      } else {
        return null;
      }
    };
  }

  validarCuit(cuit) {
    if (cuit == null || cuit == undefined || cuit == "") {
      return false;
    }

    if (cuit.length != 11) {
      return false;
    }

    var acumulado = 0;
    var digitos = cuit.split("");
    var digito = digitos.pop();

    for (var i = 0; i < digitos.length; i++) {
      acumulado += digitos[9 - i] * (2 + (i % 6));
    }

    var verif = 11 - (acumulado % 11);
    if (verif == 11) {
      verif = 0;
    }

    return digito == verif;
  }
}

import { Injectable } from "@angular/core";
import { ActualizarPaginaService } from "./actualizar-pagina.service";
import { ConfigService } from "./config.service";
import { EmpresaService } from "./empresa.service";
import { UsuarioService } from "./usuario.service";
import { FormasDePagoService } from "./formas-de-pago.service";

@Injectable({
  providedIn: "root"
})
export class MercadoPagoService {
  constructor(
    private actualizarPaginaService: ActualizarPaginaService,
    private configService: ConfigService,
    private empresaService: EmpresaService,
    private usuarioService: UsuarioService,
    private formasDePagoService: FormasDePagoService
  ) {}

  configurarCredencialesMP() {
    this.actualizarPaginaService.quitarAlerta();

    let redirect_uri = `${this.configService.urlMp}?datos={"empresa":{"id":${
      this.empresaService.empresa.id
    }},"usuario":{"id":${this.usuarioService.usuarioLogueado.id}}}`;

    redirect_uri = encodeURIComponent(redirect_uri);

    let url = `https://auth.mercadopago.com.ar/authorization?client_id=${
      this.formasDePagoService.kolektiMpClientId
    }&response_type=code&platform_id=mp&redirect_uri=${redirect_uri}`;

    window.location.href = url;
  }
}

import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-simple-box",
  templateUrl: "./simple-box.component.html",
  styleUrls: ["./simple-box.component.css"]
})
export class SimpleBoxComponent implements OnInit {
  @Input() titulo;
  @Input() descripcion;

  constructor() {}

  ngOnInit() {}
}
